import React, { Component , Fragment } from "react";
import TeamFull from "../elements/Team_full";
import FooterTwo from "../component/footer/FooterTwo";
import Header from "../component/header/Header";
import { FiCheck, FiClock, FiUser, FiMonitor, FiRadio, FiDownload, FiVideo, FiCheckCircle, FiPackage} from "react-icons/fi";
import { GoRocket } from "react-icons/go";
import { SiZoom } from "react-icons/si";
import { FaGraduationCap, FaPhone, FaRunning, FaEye, FaWhatsapp, FaPaypal, FaFacebookSquare, FaStar } from "react-icons/fa";
import { BsCircle, BsArrowLeftCircle, BsSun, BsFillPlayCircleFill, BsCloudSunFill } from "react-icons/bs";
import { IoCloseCircleOutline } from "react-icons/io5";
import { AiOutlineDown, AiOutlineRight, AiOutlineClose } from "react-icons/ai";
import { MdWork, MdEmail, MdPayments, MdModeNight } from "react-icons/md";
import ModalVideo from 'react-modal-video';
import Typical from 'react-typical';
import Modal from "react-bootstrap/Modal";
import 'custom-react-accordion/dist/Accordion.css'
import NonPassiveTouchTarget from "./NonPassiveTouchTarget";
import TouchCarousel, { clamp } from "react-touch-carousel";
import touchWithMouseHOC from "react-touch-carousel/lib/touchWithMouseHOC";
import "./styles.css";
import data from "./data";
import data2 from "./data2";
import cx from "classnames";
import AOS from 'aos';
import 'aos/dist/aos.css';
import TestimonialOne from "../blocks/testimonial/TestimonialOne";
import ReactCountryFlag from "react-country-flag";
import axios from 'axios';
import Selectrix from "react-selectrix";
import 'react-toastify/dist/ReactToastify.css';
import TestimonialTree from "../blocks/testimonial/TestimonialTree";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Spinner from 'react-bootstrap/Spinner';
import ReactPlayer from "react-player";
import FlipCountdown from '@rumess/react-flip-countdown';
// import Preguntas from "../component/dojopy/Preguntas";
import Plataforma from "../component/dojopy/Plataforma";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
// import { TbBrandCashapp } from "react-icons/tb";
import PreguntasEmpleo from "../component/dojopy/PreguntasEmpleo";
import { GiPlatform } from "react-icons/gi";
import { FaFilePdf } from "react-icons/fa6";
import { FaRegFilePdf } from "react-icons/fa6";
import { CiCalendar } from "react-icons/ci";
import { FcConferenceCall } from "react-icons/fc";
import { MdWorkOutline } from "react-icons/md";
import { TbWorldCheck } from "react-icons/tb";
import { IoVideocamOutline } from "react-icons/io5";
import { IoIosDocument } from "react-icons/io";
import { HiMiniPencilSquare } from "react-icons/hi2";


const cardSize = 300;
const cardSize2 = 280;
const cardPadCount = 2;
const carouselWidth = clamp(window.innerWidth, 0, 960);

const FECHAS_EVENTO = [
    'Hoy, 7:00 PM',
    'Jueves, 14 de diciembre, 7:00 PM',
    'Viernes, 15 de diciembre, 7:00 PM',
]


AOS.init({
    // Global settings:
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
    initClassName: 'aos-init', // class applied after initialization
    animatedClassName: 'aos-animate', // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 120, // offset (in px) from the original trigger point
    delay: 10, // values from 0 to 3000, with step 50ms
    duration: 1000, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
  });

// const then = moment('05 26 2019, 6:00 am', 'MM DD YYYY, h:mm a');
// const now = moment();
// const countdown = moment(then - now);
// const days = countdown.format('D');
// const hours = countdown.format('HH');
// const minutes = countdown.format('mm');
// const seconds = countdown.format('ss');



const VIDEO_MOBILE = "https://media.dojofullstack.com/bootcamp/ads/intro-vls-beta-v-7.mp4";
const VIDEO_DESKTOP = "https://media.dojofullstack.com/bootcamp/ads/intro-vls-beta-v-7-desktop.mp4";

const VIDEO_VLS = window.type_device ? VIDEO_MOBILE: VIDEO_DESKTOP;

const VIDEO_POSTER = window.type_device ? "https://media.dojofullstack.com/bootcamp/ads/vlcsnap-2024-10-30-16h36m12s314.png": "https://media.dojofullstack.com/bootcamp/ads/vlcsnap-2024-10-30-16h36m12s314.png";



// const Turnos =

const KeyCountry =  [
    {key: "MX", label: "México", countryNumber: "+52"},
    {key: "EC", label: "Ecuador", countryNumber: "+593"},
    {key: "GT", label: "Guatemala", countryNumber: "+502"},
    {key: "CR", label: "Costa Rica", countryNumber: "+506"},
    {key: "PE", label: "Perú", countryNumber: "+51"},
    {key: "SV", label: "El Salvador", countryNumber: "+503"},
    {key: "HN", label: "Honduras", countryNumber: "+504"},
    {key: "UY", label: "Uruguay", countryNumber: "+598"},
    {key: "PY", label: "Paraguay", countryNumber: "+595"},
    {key: "PA", label: "Panamá", countryNumber: "+507"},
    {key: "NI", label: "Nicaragua", countryNumber: "+505"},
    {key: "US", label: "Estados Unidos", countryNumber: "+1"},
  ]


function CarouselContainer(props) {
    const {
      cursor,
      carouselState: { active, dragging },
      ...rest
    } = props;
    let current = -Math.round(cursor) % data.length;
    while (current < 0) {
      current += data.length;
    }
    // Put current card at center
    const translateX =
      (cursor - cardPadCount) * cardSize + (carouselWidth - cardSize) / 2;
    return (
      <NonPassiveTouchTarget
        className={cx("carousel-container", {
          "is-active": active,
          "is-dragging": dragging
        })}
      >
        <NonPassiveTouchTarget
          className="carousel-track"
          style={{ transform: `translate3d(${translateX}px, 0, 0)` }}
          {...rest}
        />

        <div className="carousel-pagination-wrapper">
          <ol className="carousel-pagination">
            {data.map((_, index) => (
              <li key={index} className={current === index ? "current" : ""} />
            ))}
          </ol>
        </div>
      </NonPassiveTouchTarget>
    );
}


function CarouselContainer2(props) {
    const {
      cursor,
      carouselState: { active, dragging },
      ...rest
    } = props;
    let current = -Math.round(cursor) % data2.length;
    while (current < 0) {
      current += data2.length;
    }
    // Put current card at center
    const translateX =
      (cursor - cardPadCount) * 300 + (carouselWidth - 300) / 2;
    return (
      <NonPassiveTouchTarget
        className={cx("carousel-container", {
          "is-active": active,
          "is-dragging": dragging,
        })}
        style={{height: "450px"}}
      >
        <NonPassiveTouchTarget
          className="carousel-track"
          style={{ transform: `translate3d(${translateX}px, 0, 0)` }}
          {...rest}
        />

        <div className="carousel-pagination-wrapper">
          <ol className="carousel-pagination">
            {data2.map((_, index) => (
              <li key={index} className={current === index ? "current" : ""} />
            ))}
          </ol>
        </div>
      </NonPassiveTouchTarget>
    );
  }

const Container = touchWithMouseHOC(CarouselContainer);
const Container2 = touchWithMouseHOC(CarouselContainer2);

// const SlideList = [
//     {
//         textPosition: 'text-center',
//         category: '',
//         title: 'Desarrollo <br/> Web Full Stack a medida y escalable',
//         description: '',
//         buttonText: 'INSCRÍBETE HOY',
//         buttonLink: '#project'
//     }
// ]



const InfoAcordeon = {
    nodejs: "Es ideal para manejar aplicaciones de alto tráfico de usuarios y eventos, como por ejemplo Twitter donde a cada segundo se envían cientos de miles de tuits. El desarrollo de aplicaciones es mucho más rápido, las aplicaciones también y por lo tanto el usuario puede acceder a una mejor experiencia de uso",
    database: "Es muy importante saber qué, cómo y dónde se guardarán tus datos. Aprende desde cero los fundamentos y práctica para administrar DBs profesionalmente, aprende bases de datos Relacionales y No Relacionales.",
    web: "En este módulo aprenderás a crear tu sitio web partiendo del prototipo en papel. Te sumergirás en las mejores prácticas del desarrollo web, trabajando con HTML y CSS. Conocerás herramientas para optimizar al máximo tu sitio web, implementando prácticas de versionado de código con GIT, y preprocesadores como SASS. Al finalizar, sabrás cómo aplicar Bootstrap a tus proyectos, y comprenderás lo importante del SEO en tus desarrollos. Subirás tu sitio a un servidor, y aprenderás a interactuar con este servicio. También sabrás cómo presentar un presupuesto y atender a tu cliente final.  ",
    js: "En este módulo aprenderás los fundamentos del lenguaje de programación más usado en la actualidad, con el cual es posible crear aplicaciones de todo tipo. Explorarás inicialmente herramientas propias del mismo, indagando casos prácticos de aplicación y cómo aplicar técnicas de desarrollo para apps modernas con AJAX. Al graduarte, estarás en condiciones de crear soluciones web interactivas, y trasladar los conocimientos del curso a cualquier framework JavaScript.  ",
    react: "En este módulo, que es el tercer nivel del Bootcamp, aprenderás a programar por componentes, mediante Javascript y React, y también conocerás las ventajas de la utilización del flujos de datos. Comprenderás la utilización del virtual DOM mediante los desarrollos de React JS. Al finalizar, podrás crear tus propias aplicaciones SPA, y estarás en condiciones de hacer cualquier desarrollo con uno de los frameworks más populares y avanzados del momento.",
    python: "Python es uno de los lenguajes de programación más valorados y usados a nivel mundial según estadísticas de Stack Overflow la plataforma más grande de programadores, multitud de Startups y empresas lo utilizan en su día a día como Instagram, Spotify, Amazon, Netflix, Google, Facebook, Dropbox, Youtube, etc,  Python es flexible, claro y con un código legible para todos, tambien trabajaremos con el Framework Django.",
    git: "Aprende todas las herramientas necesarias para dominar el mundo de la programación colaborativa. Aprenderás las bases y conceptos esenciales que necesitas para trabajar en proyectos profesionales, ya sea en una empresa, conjunto de programadores. Con este módulo aprenderás todo lo necesario para involucrarte en cualquier grupo de programadores.",
    project: "El proyecto final se desarrolla en el sexto mes y sirve para certificar el aprendizaje poniéndolo en práctica creando una réplica de una empresa o Startup real como MercadoLibre, Instagram o Airbnb, con tus mentores. El proyecto final es una herramienta increíble para agregar a tu portafolio y así mostrar tu trabajo en tus próximas oportunidades laborales 😎!",
    deploy_aws: "Amazon Web Services (AWS) es una de las principales plataformas de servicios de computación en la nube a nivel mundial, en este Bootcamp desplegaremos el proyecto final en esta infraestructura muy potente, con tecnología de vanguardia, seguridad, confiabilidad, y escalabilidad.",
    serverless: "La arquitectura serverless también conocida como FaaS (Functions as a Service), habilita la ejecución de una aplicación mediante contenedores temporales y sin estado; estos son creados en el momento en el que se produce un evento que dispare dicha aplicación.",
    employer: "Al finalizar del bootcamp, nuestro equipo de talento te asesorará para lograr tus objetivos laborales.",
    api: "En este módulo de API REST con Django explorarás los conceptos fundamentales de las APIs REST, como los métodos HTTP, los recursos, los endpoints y la autenticación. Aprenderás cómo diseñar una API RESTful siguiendo las mejores prácticas, manteniendo la coherencia y facilitando la interacción con otras aplicaciones y servicios. ",
    apiSecure: "En este módulo aprenderás a implementar pruebas automatizadas para verificar el correcto funcionamiento de tu código, así como a identificar y solucionar posibles vulnerabilidades y riesgos de seguridad en tu aplicación. Este módulo te dotará de las habilidades necesarias para garantizar la confiabilidad y protección de tus proyectos web, ofreciendo una experiencia segura y libre de errores para los usuarios finales.",
}


class BootcampFullStackLiveEmpleo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Temario: "https://media.dojofullstack.com/FULLSTACK-2025-live-12m-brochure.pdf",
            langUS: false,
            isBeca50: false,
            Turnos: [
                {key: "noche", label: "Noche"},
                {key: "dia", label: "Mañana"},
            ],
            bootcampSlug: 'fullstack',
            bootcampSlugDescuento: 'fullstack',
            bootcampSlugPre: 'fullstack',
            bootcampSlugMensual: 'fullstack',
            bootcampSlug2: 'fullstack',
            IsEspecial24h: false,
            FooterColor: 'linear-gradient(to right, slateblue, rgb(40, 2, 17))',
            FechaEspecial24h: '',
            timestamp24h: "",
            linkPayUSD: "",
            isPricePais: false,
            semibeca_activo: false,
            semibeca_img: "",
            isMount: false,
            beca: '',
            audio: '',
            isPlayed: false,
            pricingAPI: {},
            fecha_start_end: "",
            isPriceEspecial: false,
            PriceEspecialBody: "",
            PriceEspecialPreBody: "",
            timePrueba: "",
            DataFechas: [],
            mesesInicio: [],
            fechaLimite: "",
            showWasapInput: false,
            Eventos: {},
            selectWebinarFecha: 0,
            utc_offset: '-0500',
            Plan: 1,
            formaPago: 1,
            utc_dif: 0,
            select_mes: "",
            select_turno: "",
            select_modalidad: "",
            Email: "",
            Name: "",
            LastName: "",
            Phone: "",
            fechaSelect: "fecha_1",
            isOpenTemario: false,
            isOpenSemiBeca: false,
            isOpenSuscribe: false,
            isOpen0: false,
            isOpen: false,
            isOpen2: false,
            isOpen3: false,
            isOpenResume: false,
            isOpenResumeFlex: false,
            countryCode: "US",
            price_cuota: "",
            price_cuota_2: "",
            price_cuota_22: "",
            price_completo: "",
            price_completo_regular: "",
            price_completo_2: "",
            price_completo_regular_2: "",
            price_mensual: "",
            price_preinscripcion: "",
            price_mensual_regular: "",
            titlePrice: "",
            price_ahorro: "",
            descuento: "",
            price_payment: "1",
            salario_fullstack_jr: "US$ 14,000 ANUAL",
            salario_fullstack_md: "US$ 24,000 ANUAL",
            salario_fullstack_sr: "+US$ 60,000 ANUAL",
            usa_salario_fullstack_jr: "US$ 80,000 ANUAL",
            usa_salario_fullstack_md: "US$ 100,000 ANUAL",
            usa_salario_fullstack_sr: "+US$ 140,000 ANUAL",
            isOpenCheckout: false,
            days: 0,
            mes: 1,
            hours: 0,
            minutes: 0,
            seconds: 0,
            enrollMes: "",
            enrollDay: "",
            photoIndex: 0,
            isOpenImg: false,
            MesAcceso: null,
            loadingEnroll: false,
            isCuota: 1 ,
            showMore: false,
            showMoreA: false,
            showMoreB: false,
            showMoreC: false,
            showMoreD: false,
            showMoreE: false,
            showMoreF: false,
            showMoreG: false,
            showMoreI: false,
            showPhoneField: false,
            VideoPitch: false,
            sendDataForm: false,
            isOpenShowPrueba: false,
            agendarActive: false,
            planPagoUSD: "",
            planPagoPE: "",
            isOpenPlanPay: false,
            DescuentoBody: "",
            price_mount: "",
            selectFecha: false,
            DescuentoBodyUSD: "",
            DescuentoBodyPEN: "",
            isOpenReunion: false,
            cuotaDolar: '',
            cuotaSoles: '',
            isOpenModalInfo: false,
            isModalWS: false,
            priceConversion: "",
            garantia: false,
            showButton: true,
            timeCounter: "",
            showDeadLine: false,
            isFlashEspecial: true,
            price_view_base: "",
            porcentajeDescuento: "",
            showWeb: true,
            showWebNow: false,
            showBtnWasi: false,
            showNotifPay: false
        };
        this.openModalEmail = this.openModalEmail.bind(this);
        this.openModalWebinar = this.openModalWebinar.bind(this);
        this.openModalCheckout = this.openModalCheckout.bind(this);
        // this.openModalSuscribe = this.openModalSuscribe.bind(this);
        this.openModalVid = this.openModalVid.bind(this);
        this.selectBoot = this.selectBoot.bind(this);
        this.openModal0 = this.openModal0.bind(this);
        this.openModal = this.openModal.bind(this);
        this.openModal2 = this.openModal2.bind(this);
        this.openModal3 = this.openModal3.bind(this);
        this.openModal4 = this.openModal4.bind(this);
        this.openModalPrueba = this.openModalPrueba(this);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitBeca = this.onSubmitBeca.bind(this);
        this.onRenderOption = this.onRenderOption.bind(this);
        this.OpenWS = this.OpenWS.bind(this);
        this.GetLinkWS = this.GetLinkWS.bind(this);
        this.FechaEnroll = this.FechaEnroll.bind(this);
        this.setEmailValidate = this.setEmailValidate.bind(this);
        this.searchEvento = this.searchEvento.bind(this);
        this.DynamicCountry = this.DynamicCountry.bind(this);
        this.fetchPricingData = this.fetchPricingData.bind(this);



       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);

    //    window.addEventListener('load', function() {
    //     document.title = 'Desarrollo Web Full Stack Bootcamp | 100% online en vivo | DojoFullStack';
    //     })

    }




    TrackEventConversion(){
        try {
            window.fbq('track', 'AddToCart', {
                value: 497,                 // Valor total de la compra
                currency: 'USD',               // Moneda utilizada (ISO 4217)
                content_type: 'product',       // Tipo de contenido
                content_ids: ['bootcamp'],  // ID del producto o identificador
                content_name: 'bootcamp'
            })

            // window.fbq("track", "Contact");
            window.gtag_report_conversion();
        } catch (error) {
            console.log(error);
        }
    }


    eventPing(name_event) {
        const config = {
          method: 'get',
          url: `https://api.dojofullstack.com/api/v1/crm/event/${name_event}`,
          headers: { 
            'Authorization': '0101010010001'
          }
        };
        axios(config)
          .then(response => {})
          .catch(error => {});
      };


    DynamicCountry(country='' ){

        const isParameterPresent = (param) => {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.has(param);
        }

        window.urlWasi = this.GetLinkWS();

        const newCountry = country || this.state.countryCode;

        if (country){
            
            const TemarioUSA = 'https://plataforma-dojopy-media.s3.us-east-1.amazonaws.com/FULLSTACK-2025-EN-VIVO-4m-usa_LIVE.pdf';

            this.setState({countryCode: country});

            // if (country.toLocaleUpperCase() === "US"){
            //     this.setState({bootcampSlug: "us-fullstack", bootcampSlugDescuento: "us-fullstack",
            //         Temario: TemarioUSA
            //     });
            // }
            
        }



        function getPaymentParameter(url) {
            // Crear un objeto URL a partir de la cadena de la URL
            const urlObj = new URL(url);
            // Obtener los parámetros de búsqueda
            const params = new URLSearchParams(urlObj.search);
            // Leer el valor del parámetro "payment"
            return params.get('payment') || params.get('pay');
        }

        function getDiscountParameter(url) {
            // Crear un objeto URL a partir de la cadena de la URL
            const urlObj = new URL(url);
            // Obtener los parámetros de búsqueda
            const params = new URLSearchParams(urlObj.search);
            // Leer el valor del parámetro "payment"
            return params.get('discount');
        }

        const discountActive = getDiscountParameter(window.location.href);

        if (discountActive){

            if (discountActive.includes("views")){
                toast.success("Solicita más información sobre el Bootcamp Full Stack en vivo y en línea. ¡Estamos aquí para ayudarte!");
            
                this.sleep(5000).then(r => {
                    const texto = "Hola, información sobre el Bootcamp Full Stack online en vivo.";
                    window.open(`https://api.whatsapp.com/send?phone=51936670597&text=${texto}`, '_self').focus();
                })


            } else {

                toast.success("¡Obtén ahora mismo un exclusivo código de descuento para el Bootcamp Full Stack online!");
            
                this.sleep(3500).then(r => {
                    const texto = "Hola, tengo un código de descuento para el Bootcamp Full Stack online. #CODE100OFF";
                    window.open(`https://api.whatsapp.com/send?phone=51936670597&text=${texto}`, '_self').focus();
                  })

            }

            
        

        }


        const paymentValue = getPaymentParameter(window.location.href);
        if (paymentValue !== null){
            // console.log(paymentValue);

            const codes = this.state.pricingAPI["redirect"]["codes"];

            if (codes[paymentValue]){

                if (!this.state.showNotifPay){
                    toast.success("Un momento por favor, te estamos llevando al sistema de pago.");
                }

                this.sleep(2000).then(r => {
                    // console.log("redireccionado link de pago", codes[paymentValue]);
                    window.location.href = codes[paymentValue];
                  })
                  this.setState({showNotifPay: true});

            }
        }


        
        this.setState({
            linkPayUSD: this.state.pricingAPI[this.state.bootcampSlug].linkPayUSD || '',
            select_mes:  this.state.pricingAPI[this.state.bootcampSlug]['fechas'][0]['mes'],
            isPricePais: false,
            utc_offset: '-0500',
            utc_dif: 0,
            timestamp24h: this.state.pricingAPI[this.state.bootcampSlug]['timestamp24h'],
            semibeca_img: this.state.pricingAPI[this.state.bootcampSlug]['semibeca_img'],
            DataFechas: this.state.pricingAPI[this.state.bootcampSlug]['fechas'],
            mesesInicio: this.state.pricingAPI[this.state.bootcampSlug]['mesesInicio'],
            Turnos: this.state.pricingAPI[this.state.bootcampSlug]['turnos'],
            fechaLimite: this.state.pricingAPI[this.state.bootcampSlug]['fechaLimite'],
            isPriceEspecial: this.state.pricingAPI[this.state.bootcampSlug]['isPriceEspecial'],
            PriceEspecialBody: this.state.pricingAPI[this.state.bootcampSlug]['PriceEspecialBody'],
            PriceEspecialPreBody: this.state.pricingAPI[this.state.bootcampSlug]['PriceEspecialBody'],
            timePrueba: this.state.pricingAPI[this.state.bootcampSlug]['time_prueba'],
            FooterColor: this.state.pricingAPI[this.state.bootcampSlug]['FooterColor'],
            price_completo: this.state.pricingAPI[this.state.bootcampSlug]['price']['usd']['price_view_base'], //495
            price_completo_regular: this.state.pricingAPI[this.state.bootcampSlug]['price']['usd']['price_view_base_regular'],
            price_mensual: this.state.pricingAPI[this.state.bootcampSlugMensual]['price']['usd']['price_view_final'],
            price_mensual_regular: this.state.pricingAPI[this.state.bootcampSlugMensual]['price']['usd']['price_view_base_regular'],
            titlePrice: this.state.pricingAPI[this.state.bootcampSlug]['titlePrice'],
            titlePricePre: this.state.pricingAPI[this.state.bootcampSlug]['titlePrice'],
            price_payment: "1",
            salario_fullstack_jr: "US$ 14,000 ANUAL",
            salario_fullstack_md: "US$ 24,000 ANUAL",
            salario_fullstack_sr: "+US$ 60,000 ANUAL",
            planPagoUSD: this.state.pricingAPI[this.state.bootcampSlug]['planPagoUSD'],
            planPagoPE: this.state.pricingAPI[this.state.bootcampSlug]['planPagoPE'],
            DescuentoBody: this.state.pricingAPI[this.state.bootcampSlug]['DescuentoBody'],
            price_mount: this.state.pricingAPI[this.state.bootcampSlug]['price']['usd']['mount'],
            DescuentoBodyUSD: this.state.pricingAPI[this.state.bootcampSlug]['DescuentoBodyUSD'],
            DescuentoBodyPEN: this.state.pricingAPI[this.state.bootcampSlug]['DescuentoBodyPEN'],
            cuotaDolar: this.state.pricingAPI[this.state.bootcampSlug]['cuotaDolar'],
            cuotaSoles: this.state.pricingAPI[this.state.bootcampSlug]['cuotaSoles'],
            priceConversion: this.state.pricingAPI[this.state.bootcampSlug]["priceConversion"] || '',
            garantia: this.state.pricingAPI[this.state.bootcampSlug]["garantia"] || false,
            porcentajeDescuento: this.state.pricingAPI[this.state.bootcampSlug]?.porcentajeDescuento || '',
            fecha_inicio: this.state.pricingAPI[this.state.bootcampSlug]["fecha_inicio"] || false,
            redirect: this.state.pricingAPI["redirect"] || false,
            price_completo_2: this.state.pricingAPI[this.state.bootcampSlug2]['price']['usd']['price_view_base'], //495
            price_completo_regular_2: this.state.pricingAPI[this.state.bootcampSlug2]['price']['usd']['price_view_base_regular'],
        })

        try {
            if (this.state.pricingAPI[this.state.bootcampSlug].price[country]){
                this.setState({
                    isPricePais: true,
                    price_completo: this.state.pricingAPI[this.state.bootcampSlug].price[country].price_view_base,
                    price_completo_regular: this.state.pricingAPI[this.state.bootcampSlug].price[country].price_view_base_regular,
                    price_mensual: this.state.pricingAPI[this.state.bootcampSlugMensual]['price'][country]['price_view_final'],
                    price_mensual_regular: this.state.pricingAPI[this.state.bootcampSlugMensual]['price'][country]['price_view_base_regular']
                });
            }
        } catch (error) {
            console.log('Log',error);
        }



        if (newCountry === 'PE') {
            this.setState({
                utc_offset: '-0500',
            });
        } else if (newCountry === 'MX') {
            this.setState({
                utc_dif: -1,
                utc_offset: '-0600',
                });
        } else if (newCountry === 'CR'){
            this.setState({utc_dif: -1, utc_offset: '-0600'});
        } else if (newCountry === 'CL'){
            this.setState({utc_dif: -1, utc_offset: '-0400'});
        } else if (newCountry === 'BO'){
            this.setState({
                utc_dif: -1,
                utc_offset: '-0400',
                });
        } else if (newCountry === 'CU'){
            this.setState({utc_dif: +1});
        } else if (newCountry === 'SV'){
            this.setState({utc_dif: -1, utc_offset: '-0600'});
        } else if (newCountry === 'GT'){
            this.setState({utc_dif: -1});
        } else if (newCountry === 'HN'){
            this.setState({utc_dif: -1, utc_offset: '-0600'});
        } else if (newCountry === 'NI'){
            this.setState({utc_dif: -1});
        } else if (newCountry === 'PY'){
            this.setState({utc_dif: +1, utc_offset: '-0300'});
        } else if (newCountry === 'PR'){
            this.setState({utc_dif: +1, utc_offset: '-0300'});
        } else if (newCountry === 'DO'){
            this.setState({utc_dif: +1});
        } else if (newCountry === 'UY'){
            this.setState({
                utc_dif: +2,
                utc_offset: '-0300'
            });
        } else if (newCountry === 'BR'){
            this.setState({
                utc_dif: +2,
                utc_offset: '-0300'
            });
        }

        if (newCountry === 'ES') {
            this.setState({
                utc_offset: '+0100',
            });
        }

    }


    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    OpenMeet(){
        if (this.state.Phone){
            window.open(`https://calendly.com/dojopy/bootcamp-fullstack?name=${this.state.Name}&email=${this.state.Email}&phone=${this.state.Phone}`, '_blank').focus();
        } else {
            window.open("https://calendly.com/dojopy/bootcamp-fullstack", '_blank').focus();
        }
    }


    GetLinkWS(){
        let texto = "Hola, información sobre el Bootcamp Full Stack online.";
 
        if (window.type_device){
            return `https://api.whatsapp.com/send?phone=51936670597&text=${texto}`;
        } else {
            return `https://web.whatsapp.com/send?phone=51936670597&text=${texto}`;
        }
    }

    GetLinkWSReunion(){
        let texto = "Hola, me gustaría agendar una reunión informativa sobre el bootcamp full stack web.";
    
        if (window.type_device){
            return `https://api.whatsapp.com/send?phone=51936670597&text=${texto}`;
        } else {
            return `https://web.whatsapp.com/send?phone=51936670597&text=${texto}`;
        }
    }

    OpenWS(){
        const body = this.GetLinkWS();
        window.open(body, '_blank').focus();
        fbq("track", "contact");
    }

  onRenderOption(option, complete) {
    return (
      <li>
        <ReactCountryFlag
          countryCode={option.key}
          svg
          style={{
            width: "2em",
            height: "2em",
            padding: "1px",
          }}
          title="país"
        />{" "}
        {complete ? option.countryNumber : option.key}
      </li>
    );
  }



  onRenderSelection( selected, settings, deselect ){
    return 	(<span style={{ marginRight: 10, padding: 5 }}>
{ selected ?
        <span style={{"color": "#1f1f25"}}>
            <ReactCountryFlag
            countryCode={selected.key}
            svg
            style={{
                width: '2.3em',
                height: '2.3em',
                padding: '7px'
            }}
            title="país"
    />  {selected.countryNumber}
        </span>
        :
           <span style={{padding: 5}}>País</span>
    }
    
<i style={{ paddingLeft: 5, cursor: "pointer" }} onClick={ deselect } className="fa fa-window-close"></i>
</span>)
}


    FechaEnroll(e){
        let fecha_enroll = e.currentTarget.value;
        this.setState({fechaSelect: fecha_enroll});


        window.location.href = '#fechas';
    }


    onSubmit(e) {
        e.preventDefault();
        
        
        if (this.state.Email.trim() === "" || !this.state.Email.trim().includes("@") ) {
            toast.dark('¡Ups! El campo de Email es necesario para continuar.');
            return;
        }

        if (this.state.Phone.trim() === "") {
            toast.dark('¡Ups! El campo Celular es necesario para continuar.');
            return;
          }

          window.fbq('track', 'Lead', {
            content_name: 'Registro formulario Bootcamp',
            content_category: 'Full Stack Online'
          });



        this.setState({loadingEnroll: true, sendDataForm: true});

        localStorage.setItem('email', this.state.Email.trim());
        localStorage.setItem('phone', this.state.Phone.trim());

        if (this.state.Name.trim()){
            localStorage.setItem('name', this.state.Name.trim());
        }

        const source = window.location.search.split('source=')[1] || 'home';

        axios.post('https://cprij4toee.execute-api.us-east-1.amazonaws.com/dev',
        {evento: false, lead: true, bootcamp: 'Web full stack', email: this.state.Email, name: this.state.Name, country: this.state.countryCode,
            Token: "c3b4b89c",
            landing: 'home',
            source: source,
            phone: this.state.Phone
    },)
        .then(res => {

            toast.success(`¡Hola ${this.state.Name}! 🎉 Gracias. Hemos recibido tu solicitud y nuestro equipo la revisará pronto. Si tienes alguna pregunta, no dudes en ponerte en contacto por whatsapp. ¡Estamos aquí para ayudarte!`);


            const timer = setTimeout(() => {
                window.open(this.state.Temario, '_blank').focus();
            }, 3000); // 3 segundos


            this.setState({isOpenSuscribe: false, isModalWS: false, isOpenTemario: false, isOpenModalInfo : false, selectFecha: false, loadingEnroll: false});
            // window.fbq("track", "Contact");
            // window.gtagSendEvent();
            // this.setState({isModalWS: true})
            // const timer = setTimeout(() => {
            //     const link = this.GetLinkWS();
            //     window.open(link, '_blank').focus();
            // }, 5000); // 3 segundos

              


            // window.Calendly.initPopupWidget({url: `https://calendly.com/dojofullstack/agendar?hide_event_type_details=1&hide_gdpr_banner=1&email=${this.state.Email}&name=${this.state.Name}`})

        })
    }




    onSubmitBeca(e) {
        e.preventDefault();
        if(this.state.Email === "" ){
            toast.warning('¡Ups! El campo de Email es necesario para continuar.');
            return;
        }
        // if(this.state.Phone === "" ){
        //     toast.warning('El campo WhatsApp es requerido, para ser atendido por un asesor.');
        //     return;
        // }
        this.setState({loadingEnroll: true, sendDataForm: true});

        localStorage.setItem('email', this.state.Email);
        localStorage.setItem('phone', this.state.Phone);

        // toast.success(`${this.state.Name} enviamos tus datos al equipo de Dojopy, gracias!`);
        // let cupon = window.location.search.split('ref=')[1];
        this.setState({isOpenSuscribe : false, isOpenSemiBeca: false, isOpenTemario: false, loadingEnroll: false});

        axios.post('https://cprij4toee.execute-api.us-east-1.amazonaws.com/dev',
        {beca: true , lead: true, bootcamp: 'Web full stack', email: this.state.Email, name: this.state.Name, phone: this.state.Phone, country: this.state.countryCode, fecha: this.state.selectWebinarFecha},)
        .then(res => {
            window.gtag_report_conversion();


            toast.success(`Hola!, "¡Gracias por aplicar! Hemos recibido tu solicitud y estamos emocionados de brindarte un descuento adicional. Nuestro equipo revisará tu solicitud y te enviaremos un código de descuento único en un plazo de 24 horas. ¡No olvides revisar tu bandeja de entrada! Si tienes alguna pregunta, no dudes en contactarnos. "`);
            // toast.success(`Hola ${this.state.Name}!, tengamos una breve sesión informativa para completar tu inscripción`);

        })
    }



    searchEvento(myArray, key){
        for (let i=0; i < myArray.length; i++) {
            if (myArray[i].key === key) {
                return myArray[i].value;
            }
        }
    }

    
    sendEventAddCart(){
        window.fbq('track', 'AddToCart', {
            value: 497,                 // Valor total de la compra
            currency: 'USD',               // Moneda utilizada (ISO 4217)
            content_type: 'product',       // Tipo de contenido
            content_ids: ['bootcamp'],  // ID del producto o identificador
            content_name: 'bootcamp'
          });
    }



renderCard(index, modIndex) {
    const item = data[modIndex];
    return (
        <div
        key={index}
        className="carousel-card"
        >
        <div
            className="carousel-card-inner"
            style={{backgroundImage: item.background , backgroundSize: "cover"}}
        >
            {item.title}
        </div>
        </div>
    );
    }

renderCard2(index, modIndex) {
    const item = data2[modIndex];
    return (
        <div
        key={index}
        className="carousel-card"
        >
        <div style={{width: "275px"}}>
           <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "23px", "background": "white"}}>
                <h4>
                {item.title}
                </h4>
                <img alt={item.title} height="130px" src={item.background} />
            </div>
        </div>
        </div>
    );
    }


renderBeca() {
    return (
        <>

 {/* Start PRicing Table Area  */}
 <div className="col-12 mt-1" style={{paddingBottom: "13px", paddingRight: "15px", paddingLeft: "15px", maxWidth: "470px", marginRight: window.type_device ? "auto": "1px" }} >


<div className="rn-pricing" style={{boxShadow: "rgba(140, 82, 255, 0.9) 4px 4px 15px 3px", border: "none", background: "linear-gradient(135deg, #C56CD6 0%,#3425AF 100%)", borderRadius: "20px"}} >
    <div className="pricing-table-inner">

        <div className="pricing-header pb-0 mb-2 mt-1">
  
        
        <span style={{padding: "6px 10px", margin: "5px", "background-color": "tomato", "border-radius": "7px", "color": "white", "font-size": "20px", fontWeight: "bold"}}>
                {this.state.countryCode === 'PE' ? this.state.DescuentoBodyPEN: this.state.DescuentoBodyUSD}
        </span>


        <h3 className="text-white mt-1 mb-0">Monto Total con la Beca50</h3>
             
            <span style={{color: "white", display: "inline-block"}}>
                <del style={{color: "lightgray", fontSize: "25px"}}>
                &nbsp; {this.state.price_completo_regular} &nbsp;
                </del>
            </span>


            <h3 className="pricing" style={{marginBottom: "5px"}} >
            <span style={{color: "white", fontSize: "18px"}}>Ahora: </span>

            {this.renderPrice(this.state.price_completo)}
            </h3>


         

            <div className="slide-btn" style={{marginTop: "15px", textAlign: "center"}}>
                <a href={`#empezar}`} >
                <button
                    onClick={() => {
                    window.fbq('track', 'AddToCart', {
                        value: 497,// Valor total de la compra
                        currency: 'USD',               // Moneda utilizada (ISO 4217)
                        content_type: 'product',       // Tipo de contenido
                        content_ids: ['bootcamp'],  // ID del producto o identificador
                        content_name: 'bootcamp'
                      });
                }}
                id="enroll_bootcamp_2"
                style={{border: "1px solid white", lineHeight: "28px", fontSize: "23px", marginBottom: "13px", padding: "15px 20px" }}
                className=" btn-efect">
                    <HiMiniPencilSquare className="mr-1" />

                Inscríbete Ahora

                 </button>
                </a>
             </div>



        </div>
    </div>


    


</div>
</div>
{/* End PRicing Table Area  */}


        </>
    )
}


renderPais(complete=false){
    return (
        <>
        <span style={{
            fontSize: "17px",
            fontWeight: "bold",
            position: "absolute",
            left: "15px",
            top: "-25px",
            zIndex: "1",
        }} > {true ? "Zona horaria:": "Time zone:"} </span>
            <Selectrix
        height={300}
        onRenderOption={(option) => this.onRenderOption(option, complete)}
        onRenderSelection={(option) => this.onRenderSelection(option, complete)}
        placeHolderInside={true}
        placeholder={"País"}
        customScrollbar={true}
        searchable={false}
        materialize={false}
        defaultValue={this.state.countryCode}
        options={KeyCountry}
        onChange={value => {
            // if (value.key === 'US'){ window.location.href = '/us'}
            this.DynamicCountry(value.key)}
        }
    />
        </>

    )
}




renderMes(){
    return (
        <>
        <span style={{
            fontSize: "17px",
            fontWeight: "bold",
            position: "absolute",
            left: "15px",
            top: "-25px",
            zIndex: "1",
        }} > {true ? "Mes:": "Month:"} </span>
        <Selectrix
        height={250}
        placeHolderInside={true}
        placeholder={true ? "Todos": "All" }
        customScrollbar={true}
        searchable={false}
        materialize={false}
        defaultValue={this.state.select_mes}
        options={this.state.mesesInicio.map((item) => ({key: item, label: item})) }
        onChange={value => this.setState({select_mes: value.key})}
    />
        </>

    )
}

renderTurno(){
    return (
        <>
        <span style={{
            fontSize: "17px",
            fontWeight: "bold",
            position: "absolute",
            left: "15px",
            top: "-25px",
            zIndex: "1",
        }} >{true ? "Turno": "Shift"}:</span>
        <Selectrix
        height={250}
        placeHolderInside={true}
        placeholder={true ? "Todos": "All" }
        customScrollbar={true}
        searchable={false}
        materialize={false}
        defaultValue={this.state.select_turno}
        options={this.state.Turnos}
        onChange={value => this.setState({select_turno: value.key})}
    />
        </>

    )
}


renderPrice(price,  prueba=false){
        return (
            <>
                <div className="row" style={{placeContent: "center", fontFamily: "'Poppins',sans-serif"}}>
                <div className="col-12">

                    <p style={{marginTop: '5px', fontFamily: "'Poppins',sans-serif", fontSize: window.type_device ? '39px': '45px', "color": prueba ? "#1f1f25": "white", fontWeight: "bold", paddingTop: "7px", paddingBottom: "7px"}}>
                    {price}
                    </p>
                </div>
                </div>
           
            </>
        )
}


renderInfoBootcamp(){
    return (
        <div>

        <p style={{marginBottom: window.type_device ? "8px": "13px", fontSize: "17px"}}>
        <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
        ¿Qué es el Desarrollo Web Full Stack y por qué tiene tanta demanda?
        </p>
        <p style={{marginBottom: window.type_device ? "8px": "13px", fontSize: "17px"}}>
        <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
            Nuestra metodología práctica y 100% online.
        </p>

        <p style={{marginBottom: window.type_device ? "8px": "13px", fontSize: "17px"}}>
        <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
        Ruta de aprendizaje paso a paso.
        </p>
    
        <p style={{marginBottom: window.type_device ? "8px": "13px", fontSize: "17px"}}>
        <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
        Modalidades disponibles: <b> En vivo o A tu ritmo</b>,  tú eliges.
        </p>

        <p style={{marginBottom: window.type_device ? "8px": "13px", fontSize: "17px"}}>
        <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
        Cómo funciona nuestra <b> Garantía de Empleabilidad.</b> 
        </p>

        <p style={{marginBottom: window.type_device ? "8px": "13px", fontSize: "17px"}}>
        <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
        Próxima fecha de inicio del Bootcamp.
        </p>

        <p style={{marginBottom: window.type_device ? "8px": "13px", fontSize: "17px"}}>
        <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
        Certificación al finalizar el programa.
        </p>
        
        <p style={{marginBottom: window.type_device ? "8px": "13px", fontSize: "17px"}}>
        <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
            ¿Necesito experiencia previa? (¡La respuesta es no!).
        </p>

        <p style={{marginBottom: window.type_device ? "8px": "13px", fontSize: "17px"}}>
        <span style={{"color":"slateblue", "fontSize": "25px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
            Costo total con opción de <b>Beca Parcial disponible. </b> 
        </p>

        </div>
    )
}

renderPriceFooter(price){
        return (
            <>
                <span style={{fontSize: "17px", "color": "white", fontWeight: 500, paddingTop: "5px", paddingBottom: "5px"}}>
                    2 pagos de {price}
                </span>
                <ReactCountryFlag
                        countryCode={this.state.countryCode}
                        svg
                        style={{
                            width: '3.5em',
                            height: '3.5em',
                            padding: '9px'
                        }}
                        title="país"
                    />
            </>
        )
      }

      renderHorario(){
            if (this.state.countryCode === "PE" || this.state.countryCode === "EC" || this.state.countryCode === "CO") {
                return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">
                    <ReactCountryFlag
                countryCode={this.state.countryCode}
                svg
                style={{
                    width: '2.5em',
                    height: '2.5em',
                    padding: '8px'
                }} title="país" /> 7:00 PM a 09:00 PM</p>
            } else if (this.state.countryCode === "MX" || this.state.countryCode === "HN" || this.state.countryCode === "CR") {
                return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1"><ReactCountryFlag
                countryCode={this.state.countryCode}
                svg
                style={{
                    width: '2.5em',
                    height: '2.5em',
                    padding: '8px'
                }} title="país" />6:00 PM a 08:00 PM</p>
            } else if (this.state.countryCode === "BO") {
                return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1"><ReactCountryFlag
                countryCode={this.state.countryCode}
                svg
                style={{
                    width: '2.5em',
                    height: '2.5em',
                    padding: '8px'
                }} title="país" />8:00 PM a 10:00 PM</p>
            }  else {
                return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1"><ReactCountryFlag
                countryCode={this.state.countryCode}
                svg
                style={{
                    width: '2.5em',
                    height: '2.5em',
                    padding: '8px'
                }} title="país" />7:00 PM a 09:00 PM</p>
            }
      }

      renderHorarioFinSemana(){
        if (this.state.utc_offset === "-0500") {
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1"><ReactCountryFlag
                countryCode={this.state.countryCode}
                svg
                style={{
                    width: '2.5em',
                    height: '2.5em',
                    padding: '8px'
                }} title="país" />10:00 AM a 12:00M</p>
        } else if (this.state.utc_offset === "-0600"){
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1"><ReactCountryFlag
                countryCode={this.state.countryCode}
                svg
                style={{
                    width: '2.5em',
                    height: '2.5em',
                    padding: '8px'
                }} title="país" />10:00 AM a 12:00M</p>
        } else if (this.state.utc_offset === "-0400"){
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1"><ReactCountryFlag
                countryCode={this.state.countryCode}
                svg
                style={{
                    width: '2.5em',
                    height: '2.5em',
                    padding: '8px'
                }} title="país" />10:00 AM a 12:00M</p>
        } else if (this.state.utc_offset === "+0100"){
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1"><ReactCountryFlag
                countryCode={this.state.countryCode}
                svg
                style={{
                    width: '2.5em',
                    height: '2.5em',
                    padding: '8px'
                }} title="país" />10:00 AM a 12:00M</p>
        } else {
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1"><ReactCountryFlag
                countryCode={this.state.countryCode}
                svg
                style={{
                    width: '2.5em',
                    height: '2.5em',
                    padding: '8px'
                }} title="país" />10:00 AM a 12:00M</p>
        }

    }

    renderHorarioFinSemanaTarde(){
        if (this.state.utc_offset === "-0500") {
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">03:00 PM a 05:30 PM</p>
        } else if (this.state.utc_offset === "-0600"){
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">02:00 PM a 04:35 PM</p>
        } else if (this.state.utc_offset === "-0400"){
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">04:00 PM a 06:35 PM</p>
        } else if (this.state.utc_offset === "+0100"){
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">09:00 PM a 11:35 PM</p>
        } else {
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">03:00 PM a 05:35 PM</p>
        }
    }


    obtenerDiaActual() {
        try {
            const diasDeLaSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
            const fechaActual = new Date();
            const diaActual = fechaActual.getDay(); // Obtiene el índice del día (0=domingo, 1=lunes, etc.)
            return diasDeLaSemana[diaActual]; // Devuelve el día correspondiente en texto
        } catch (error) {
            return ""
        }
    }


    renderFechaSelect(item, key){
        return (
            <div key={key} className="row row--35 align-items-center rn-address" data-aos="fade-up"  style={{boxShadow: "#c6c9d8 8px 6px 15px 10px"}} >
            <div className="col-12 mb-3">

                <h2 style={{marginBottom: "0px", fontSize: window.type_device && "29px" }}>
                {item.fecha_inicio}
                </h2>

                <div style={{width: window.type_device ? "200px": "450px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "20px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>
                
           
                <div className="row">
                    <div className="col-lg-2 col-md-3 col-12  mt-2">
                    <span style={{padding: "3px", marginLeft: "8px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "20px"}}>
                                                <span className="pulsar"></span> {!this.state.langUS ? "En vivo": "Live"}
                                            </span>
                    </div>
                    <div className="col-md-4 col-lg-3 col-12  mt-2">
                        <span style={{"padding-top": "4px","padding-bottom": "4px", "font-size": "18px", "color":"white", "background-color": "slateblue", "border-radius": "8px", "padding-left": "8px", "padding-right": "8px"}}>
                        {item.plazas} Plazas Disponibles
                        </span>
                    </div>
                </div>

            </div>

            <div className="col-md-3 col-12" style={{height: "85px", borderRight: !window.type_device ? "1px solid #121212" : "none" }}>
                <div> <h5 className="mb-2" ><b> {true ? "Duración": "Duration"} </b></h5> </div>
                <div> <p style={{fontSize: "18px"}}> 12 meses
                <span style={{display: "inline-block"}}></span>
                </p> </div>
           
            </div>

            <div className="col-md-3 col-12" style={{height: "85px", marginTop: window.type_device ? "3px": "none", borderRight: !window.type_device ? "1px solid #121212" : "none" }}>
                <div> <h5 className="mb-2"><b> {true ? "Días": "Days"} </b></h5> </div>
                <div> <p style={{fontSize: "18px"}}> {item.dias} </p> </div>
            </div>

            <div className="col-md-3 col-12" style={{height: "85px", marginTop: "none"}}>
            <div> <h5 className="mb-2">
                <b>
                Horario
                 {item.turno === 'dia' &&  <> (Mañana) <BsSun style={{height: "35px", width: "35px", color: "goldenrod", marginLeft: "3px", fontSize: "26px"}}/>  </>  }
                 {item.turno === 'tarde' && <> (Tarde) <BsCloudSunFill style={{height: "35px", width: "35px", color: "darkblue", marginLeft: "3px", fontSize: "26px"}} /> </>  }
                 {item.turno === 'noche' && <> (Noche) <MdModeNight style={{height: "35px", width: "35px", color: "darkblue", marginLeft: "3px", fontSize: "26px"}} /> </>   }
                </b>
                </h5> </div>
                <div className="row">
                    <div className="col-12">

                    

                    {(item.turno == 'noche' || item.turno == 'night') && this.renderHorario() }
                    {item.turno == 'dia'  && this.renderHorarioFinSemana() }
                    {item.turno == 'tarde'  && this.renderHorarioFinSemanaTarde() }
                    </div>

                </div>
            </div>
            


<div className="col-12" style={{boxShadow: "none", padding: "8px"}}>
<a href={`#empezar`} >
<button onClick={this.sendEventAddCart} className="btn-efect-2" style={{border: "1px solid white", padding: "10px 25px", fontSize: '21px', textTransform: "none", fontFamily: "Poppins, sans-serif"}} >
    <HiMiniPencilSquare className="mr-1" />

    Inscríbete Ahora

</button>
</a>

<button onClick={() => {this.setState({isOpenSuscribe: true}); this.sendEventAddCart() } }
    className="btn-efect-2" style={{marginTop: window.type_device ? "10px": "", marginLeft: !window.type_device ? "13px": "", border: "1px solid white", backgroundColor: "#4b0082", padding: "10px 15px", fontSize: '21px', textTransform: "none", fontFamily: "Poppins, sans-serif"}}
    >
    <IoIosDocument className="mr-1"/>
    Descargar Brochure
</button>

</div>










        </div>
        )
      }


      renderFechas() {
        return (
            <div>

                { this.state.DataFechas.map((item, key) => (

                    this.renderFechaSelect(item, key)

                ))}
          </div>

        )}


    selectBoot(plan){
        this.setState({Plan: plan});
        window.location.href='#enroll';
    }

    openModalVid (){
        this.setState({isOpenVid: !this.state.isOpenVid});
    }
    openModal0 () {
        this.setState({isOpen0: !this.state.isOpen0});
    }
    openModalEmail (){
        this.setState({isOpenTemario: !this.state.isOpenTemario});
    }

    openModalWebinar (){
        this.setState({isOpenSuscribe: true});
    }

    openModalCheckout (){
        this.setState({isOpenCheckout: !this.state.isOpenCheckout});
        this.setState({price_payment: "1"});
        // if (type_bootcamp === 1){
        //     this.setState({price_payment: "95"});
        // } else {
        //     this.setState({price_payment: "175"});
        // }

    }

    // openModalSuscribe (e){
    //     // window.location.href = "/Inscripcion";
    //     // if (e){
    //     //     this.setState({fechaSelect: e.target.value});
    //     // }
    //     this.setState({isOpenSuscribe: !this.state.isOpenSuscribe});
    // }

    openModal () {
        this.setState({isOpen: !this.state.isOpen});
    }

    openModal2 () {
        this.setState({isOpen2: !this.state.isOpen2});
    }
    openModal3 () {
        this.setState({isOpen3: !this.state.isOpen3});
    }
    openModal4(){
        this.setState({isOpenResume: !this.state.isOpenResume});
    }

    openModalPrueba(){
        this.setState({isOpenShowPrueba: !this.state.isOpenShowPrueba});
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open');
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
    stickyHeader () {}


    validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

    setEmailValidate (email){
        this.setState({ Email: email});
        if (this.validateEmail(email)){
            this.setState({showPhoneField: true});
        }
    }



    render(){
        // const PostList = BlogContent.slice(0 , 5);

        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }

        const style = {
            marginTop: '12px',
            color: '#2F2F2F',
            border: '2px solid #2F2F2F'
          };

          const TypingAnimation =  React.memo(()=>{
            return true ?
            <Typical
            className={'my-tipical'}
            steps={['Domina el desarrollo Full Stack', 500, 'y alcanza el empleo de tus sueños.']}
            loop={true}
            wrapper="h2"
                />:
                <Typical
                className={'my-tipical'}
                steps={['Create Full Stack Web Platforms', 500, 'Get your dream job.', 500, 'The best Full Stack Bootcamp in 5 months.']}
                loop={true}
                wrapper="h2"
                />
          },(props,prevProp)=> true ); // this line prevent re rendering

          const TypingAnimation2 =  ()=> {
            return <Typical
            steps={['Aprende 100% online en vivo', 500 ,'con la guía de tu Mentor.', 1000]}
            loop={Infinity}
            wrapper="h2"/>
          }; // this line prevent re rendering

        
          const ButonsFechas = () => {
            return (
                <div data-aos="fade-right" className="col-12 col-md-6"
                style={{marginBottom: "10px",
                      paddingLeft: window.type_device ? "5px": "50px",
                       paddingRight: window.type_device ? "5px": "50px"}}
              >
                  <div style={{textAlign: "center", padding: "5px", borderRadius: "20px"}}>

           
             


                    {FECHAS_EVENTO.map((fecha, index) => (
                            <div className="my-3" key={index} >
                            <button  onClick={() => this.setState({selectFecha: true})}  className="btn-efect" style={{width: "100%", boxShadow: "2px 2px 5px 1px rgba(140, 82, 255, 0.9)"}}>
                                {fecha}
                            </button>
                            </div>
                    ))}
                

                  {/* <div className="my-3" >
                  <button  onClick={() => this.setState({selectFecha: true})}  className="btn-efect" style={{width: "100%"}}>
                    Viernes, 8 de diciembre, 7:00 PM
                  </button>
                  </div>

                  <div className="my-3" >
                  <button  onClick={() => this.setState({selectFecha: true})}  className="btn-efect" style={{width: "100%"}}>
                  Sábado, 9 de diciembre, 12:00M
                  </button>
                  </div> */}

                  </div>
      </div>
            )
          }


        return (
            <Fragment>

                <Helmet>
                    <title>
                        {true ? "Bootcamp Full Stack Web | 100% online en vivo | DojoFullStack": "Full Stack Web Bootcamp | 100% live online | DojoFullStack"}
                           </title>
                    <meta name="description"
                        content="Conviértete en Desarrollor Web Full Stack en nuestro Bootcamp de 4 meses online en vivo, domina el Frontend y el Backend de un sitio web usando HTML, CSS, JavaScript, React, Python y AWS, recibe el Programa de empleabilidad al finalizar. ¡Crea proyectos web reales y aprende las mejores prácticas de programación en DojoFullStack!"
                        />
                    <meta
                        property="og:description"
                        content="Conviértete en Desarrollor Web Full Stack en nuestro Bootcamp de 4 meses online en vivo, domina el Frontend y el Backend de un sitio web usando HTML, CSS, JavaScript, React, Python y AWS, recibe el Programa de empleabilidad al finalizar. ¡Crea proyectos web reales y aprende las mejores prácticas de programación en DojoFullStack!"
                        />
                   <meta
                        name="twitter:description"
                        content="Conviértete en Desarrollor Web Full Stack en nuestro Bootcamp de 4 meses online en vivo, domina el Frontend y el Backend de un sitio web usando HTML, CSS, JavaScript, React, Python y AWS, recibe el Programa de empleabilidad al finalizar. ¡Crea proyectos web reales y aprende las mejores prácticas de programación en DojoFullStack!"
                    />
                </Helmet>

                {/* Start Header Area  */}
                <Header callbackOpenModal={() => this.setState({isOpenSuscribe: true}) } bootcampSlug={this.state.bootcampSlug}  langES={this.state.countryCode === 'ES' ? true : false}  countryCode={this.state.countryCode} DynamicCountry={this.DynamicCountry} />
                {/* End Header Area  */}

                {/* Start Slider Area   */}

                <div className="slider-activation slider-creative-agency with-particles" id="home">

                { false &&
                <div className="frame-layout__particles">
                <Particles
                    init={this.customInit}
                    options={{
                        fullScreen: { enable: false },
                        fpsLimit: 120,
                        interactivity: {
                        events: {
                            onHover: {
                                enable: true,
                                mode: "repulse",
                            },
                            resize: true,
                        },
                        modes: {
                            push: {
                                quantity: 4,
                            },
                            repulse: {
                                distance: 200,
                                duration: 0.4,
                            },
                        },
                        },
                    particles: {
                        color: {
                            value: "#ffffff",
                        },
                        links: {
                            color: "#ffffff",
                            distance: 150,
                            enable: true,
                            opacity: 0.5,
                            width: 1,
                        },
                        collisions: {
                            enable: true,
                        },
                        fullScreen: { enable: false },
                        move: {
                            direction: "none",
                            enable: true,
                            outModes: {
                                default: "bounce",
                            },
                            random: false,
                            speed: 2,
                            straight: false,
                        },
                        number: {
                            density: {
                                enable: true,
                                area: 800,
                            },
                            value: 20,
                        },
                        opacity: {
                            value: 0.5,
                        },
                        shape: {
                            type: "circle",
                        },
                        size: {
                            value: { min: 1, max: 5 },
                        },
                    },
                }}

                />;

    </div>
    }

                    <div className="bg_image" style={{backgroundImage: 'linear-gradient(rgb(0 0 0 / 50%), rgb(91 18 237 / 95%)), url(/assets/images/bg/bg-image-27.webp)', paddingBottom: window.location.pathname.includes("unirme") && !this.state.showWeb ? "250px": "" }}>
                            
                

                            <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" style={{flexWrap: "wrap"}}>


                {false && window.location.pathname.includes("unirme") &&
                    <>

                    { !this.state.showWeb &&
                      <div className="col-12 text-center text-white px-1" >
                        <h3 className="my-1"
                        style={{
                            textAlign: "center",
                          lineHeight: window.type_device ? "30px": "65px"
                        }}
                        >
                        
                        <span
                          className=" title-gradient-funnel"
                           style={{fontFamily: "'Poppins',sans-serif",
                            fontStyle: "italic", fontSize: window.type_device ? "33px !important": "50px" }}
                           >
                          Transforma Tu Carrera en Desarrollo <br/> Web Full Stack
                          </span>
                        </h3>

                        <h5 className="my-2 px-3" style={{color: "white", fontSize: "19px", fontStyle: "italic"}} >
                        ¿Quieres Reinventarte y Encontrar la Carrera de tus Sueños?
                        </h5>

                        <h5 style={{fontWeight: "normal", fontStyle: "italic", marginBottom: "5px", fontSize: "15px", color: "whitesmoke"}}>Clic sobre el Vídeo 🔊 para activar el audio</h5> 
                      
                      </div>
                    }

                    <div className="col-12 w-100 px-0 col-md-8 col-lg-7" style={{marginTop: "10px"}}   >
                        <iframe style={{borderRadius: "8px"}} id="iframe-video" allowFullScreen frameborder="0"  border="none" width={"100%"} height={"auto"}
                        src={`https://player.dojofullstack.com/?url=${VIDEO_VLS}&poster=${VIDEO_POSTER}`}></iframe>
                      </div>
                    </>
                }

      
                  




                  {this.state.showWeb &&  <div className="container mt-1">


<div className="col-12 px-0">
<div className="d-inline-flex">
{this.state.fechaLimite &&
      <FlipCountdown
        size={'small'}
        yearTitle='Year'
        dayTitle='Días'
        hourTitle='Horas'
        minuteTitle='Min'
        secondTitle='Segs'
        monthTitle='Mes'
        hideYear
        hideMonth
        endAt={this.state.fechaLimite} // Date/Time
    />
     }
</div>

</div>


                                    <div className="row" style={{flexWrap: 'nowrap'}}>

                                        <div className="col-12 px-2">

                                                <div className="inner text-left" style={{"text-align-last": "left"}}>

                               

                        
               

                                                    {true ?
                                                    <>
                                                    <h1 className="title" style={{fontSize: window.type_device ? "": "50px", marginTop: window.type_device ? "20px": "30px", "text-align": "left", marginBottom: window.type_device ? "1px": "25px", lineHeight: "30px"}}>DEVELOPER</h1>
                                                    <h1 className="title" style={{"text-align": "left", marginBottom: "10px"}}>
                                                         <span className="h1-dojopy title title-gradient">
                                                            FULL STACK  <br/>
                                                            Web
                                                            </span>
                                                    </h1>
                                                    </>
                                                    :
                                                    <h1 className="title" style={{"text-align": "left", marginBottom: "10px"}}>
                                                        <span className="h1-dojopy title title-gradient" >FULL STACK</span>
                                                        WEB DEVELOPMENT
                                                    </h1>}

                                                    <div className="row col-12 mb-3" style={{textAlign: "left", height: "60px"}}>
                                                    <TypingAnimation/>
                                                    </div>



                                                    <p className="description" style={{"padding-top": "9px", fontSize: "17px"}}>



                                                    <div className="row">

                                                        <div style={{"text-align-last": "right"}} className="col-1 mx-0 px-0"><FiCheck style={{"color": "yellow"}}/></div>
                                                        <div className="col pr-0" style={{fontSize: "18px", paddingBottom: "9px", textAlign: "left" }}>
                                                        <IoVideocamOutline style={{fontSize: "25px"}} />  100% online
                                                            <span style={{lineHeight: "21px", display: 'inline-block', padding: "3px",marginLeft: "5px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "16px"}}>
                                                            <span className="pulsar"></span>  En vivo
                                                            </span>
                                                            
                                                         </div>
                                                    </div>


                                                    <div className="row">
                                                        <div style={{"text-align-last": "right"}} className="col-1 mx-0 px-0"><FiCheck style={{"color": "yellow"}}/></div>
                                                            <div className="col pr-0" style={{fontSize: "18px", paddingBottom: "9px", textAlign: "left" }}>
                                                            <CiCalendar style={{fontSize: "25px"}} /> Duración: 12 meses
                                                         </div>
                                                    </div>


                                                {this.state.countryCode !== "PE" &&
                                                    <div className="row">
                                                        <div style={{"text-align-last": "right"}} className="col-1 mx-0 px-0"><FiCheck style={{"color": "yellow"}}/></div>
                                                        <div className="col pr-0" style={{fontSize: "18px", paddingBottom: "9px", textAlign: "left" }}>
                                                        <MdWorkOutline style={{fontSize: "25px"}} />  Garantía de empleo o devolución del 100% del dinero.

                                                         </div>
                                                    </div>
                                                }


                                                

                                                    <div className="row">
                                                        <div style={{"text-align-last": "right"}} className="col-1 mx-0 px-0"><FiCheck style={{"color": "yellow"}}/></div>
                                                        <div className="col pr-0" style={{fontSize: "18px", paddingBottom: "5px", textAlign: "left" }}>
                                                        <TbWorldCheck style={{fontSize: "25px"}} /> Basado en proyectos
                                                         </div>
                                                    </div> 



                                                    </p>




{
<div className="d-flex" style={{gap: "13px", flexDirection: "column"}}>

<div className="slide-btn mt-0">
<a href={`#fechas`}  >

<button onClick={() => {
    window.fbq('track', 'AddToCart', {
        value: 497,                 // Valor total de la compra
        currency: 'USD',               // Moneda utilizada (ISO 4217)
        content_type: 'product',       // Tipo de contenido
        content_ids: ['bootcamp'],  // ID del producto o identificador
        content_name: 'bootcamp'
      });

} }
className="btn-efect-2 mt-2" style={{border: "1px solid white", padding: "10px 25px", fontSize: '21px', textTransform: "none", fontFamily: "Poppins, sans-serif"}}
>
<HiMiniPencilSquare className="mr-1" />
Inscríbete Ahora

</button>
</a>
</div>


{false &&
<div className="w-100 px-0 text-center">
    <a  target="_blank" href="https://api.whatsapp.com/send?phone=51936670597&text=Hola, información sobre el Bootcamp Full Stack online en vivo.">
      <button
      onClick={() => {
        window.fbq('track', 'AddToCart', {
            value: 497,                 // Valor total de la compra
            currency: 'USD',               // Moneda utilizada (ISO 4217)
            content_type: 'product',       // Tipo de contenido
            content_ids: ['bootcamp'],  // ID del producto o identificador
            content_name: 'bootcamp'
          });
    }}
                className="btn-grupo-ws mt-2 text-center"
              style={{backgroundColor: "springgreen", color: "black",
              fontFamily: "Poppins,sans-serif", fontSize: "18px", fontWeight: "bold", textTransform: "uppercase"}}>
            
            <FaWhatsapp style={{marginRight: "5px", fontSize: "25px"}} />
            ¿Tienes una pregunta?
          </button>
    </a>
  </div>
    }


</div>
    }



{true &&
<div className="d-flex" style={{gap: "13px", flexDirection: "column"}}>

<div className="slide-btn">

<button onClick={() => {this.setState({isOpenSuscribe: true}); this.sendEventAddCart() } }
    className="btn-efect-2" style={{border: "1px solid white", backgroundColor: "#4b0082", padding: "10px 25px", fontSize: '21px', textTransform: "none", fontFamily: "Poppins, sans-serif"}}
    >
        <IoIosDocument className="mr-1" />
       Descargar Brochure
</button>
</div>
</div>
}



                    <div className="mt-3">
                    <img alt="" height={window.type_device ? "70px": "79px"} src="/assets/images/dojopy/dojofullstack_report.png" />
                    <span style={{marginLeft: "5px", color: "white", "font-size": "13px", "paddingTop": "20px", fontStyle: "italic"}}>  
                    Excelencia Asegurada en la Formación </span>
                    </div> 





                    </div>

                 

                                            </div>


           

                                        </div>
                                    </div>
    }

                                </div>
                        </div>
                </div>
                {/* End Slider Area   */}



              
{this.state.countryCode !== "US" &&  this.state.showWeb && 
<div className="about-wrapper" style={{paddingTop: window.type_device ? "20px": "29px", paddingBottom: "5px", background: "white" }}>
<div data-aos="fade-top"  className={window.type_device ? "container-fluid ": "container"}>

 <div className="d-flex mt-1 text-dark" style={{flexDirection: window.type_device ? "column": "row", gap: window.type_device ? "1px": "15px" }}>

 <div className="d-flex mb-3 col-md-4" style={{borderRadius: "20px", border: "1px solid mediumpurple", padding: "5px", alignItems: "center"}}>
    <img className="ml-1" style={{borderRadius: "30px", marginRight: "5px"}} height="89px" src="/assets/images/dojopy/1-face.webp" />
    <div className="ml-2 mt-2">
      <h5 style={{fontSize: "17px", color: "#1f1f25", fontStyle: "italic"}} className="my-0">Elon Musk
        <span style={{marginLeft: "5px", fontSize: "13px", color: "mediumseagreen"}}>CEO SpaceX & Twitter</span>
      </h5>
      <p style={{fontSize: "14px", fontStyle: "italic"}}>La vida es demasiado corta para ser pequeña. Tienes que hacer algo grande.</p>
    </div>
  </div>

<div className="d-flex mb-3 col-md-4" style={{borderRadius: "20px", border: "1px solid mediumpurple", padding: "5px", alignItems: "center"}}>
    <img className="ml-1" style={{borderRadius: "30px", marginRight: "5px"}} height="89px" src="/assets/images/dojopy/3-face.jpg" />
    <div className="ml-2 mt-2">
      <h5 style={{fontSize: "17px", color: "#1f1f25", fontStyle: "italic"}} className="my-0">Mark Zuckerberg
        <span style={{marginLeft: "5px", fontSize: "13px", color: "mediumseagreen"}}>CEO Facebook</span>
      </h5>
      <p style={{fontSize: "14px", fontStyle: "italic"}}>
      El miedo a lo desconocido nos limita; arriesgarse es clave para conocer nuestras capacidades.
        </p>
    </div>
  </div>



</div>
</div>
</div>
    }



{this.state.showWeb && 
  <div data-aos="fade-up" className="about-area bg_color--5" id="about_dojopy" style={{marginTop: "20px", marginBottom: "30px"}}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">

                                <div className="col-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">

                                                {window.type_device &&
                                                <>
                                                      Developer <br/> Full Stack Web
                                                </>}

                                                {!window.type_device &&
                                                <>
                                                      Developer Full Stack<br/> Web
                                                </>}


                                            <span style={{display: "inline-block", padding: "3px", marginLeft: "8px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "20px"}}>
                                                <span className="pulsar"></span> {true ? "En vivo": "Live"}
                                            </span>

                                            </h2>
                                            <p>

                                                {this.state.countryCode === "US" &&
                                                <b>
Dojofullstack es una startup educativa que se dedica a empoderar a los creadores de tecnología y programación.
                                                </b>
                                                }

                                            {this.state.countryCode !== "US" &&
                                                <b>
Dojofullstack es una startup educativa que se dedica a empoderar a los creadores de tecnología y programación.
                                                </b>
                                                }
                                            </p>

                                            {this.state.countryCode === "US" ?
                                                <>
                                                    <p>
                                                    Nacemos con la misión de potenciar el talento en Tech y abordar la creciente demanda de programadores a nivel mundial.
                                                    </p>
                                                    <p>
                                                    Nuestro propósito es cerrar la brecha de habilidades, generar más oportunidades y desarrollar el talento de miles de estadounidenses que desean ser parte de la transformación digital y el avance tecnológico en las empresas.
                                                    </p>
                                                </>: 
                                                <>
                                                   <p>
                                                    Nacemos con la misión de potenciar el talento latinoamericano y abordar la creciente demanda de programadores a nivel mundial.
                                                    </p>
                                                    <p>
                                                    Nuestro propósito es cerrar la brecha de habilidades, generar más oportunidades y desarrollar el talento de miles de latinos que desean ser parte de la transformación digital y el avance tecnológico en las empresas.
                                                    </p>
                                                </>
                                            }

                                          

                                        </div>

                                    <div className="row mt--30 rn-address" style={{boxShadow: "#c6c9d8 8px 6px 15px 1px"}} >
                                            <div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{marginTop: window.type_device? "10px": "1px"}}>
                                                <div className="about-us-list">
                                                    <h3 className="title"><FiClock/> {true ? "Duración": "Duration"}</h3>
                                                    <p style={{fontSize: "18px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "18px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    Duración: 12 meses
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{marginTop: window.type_device? "10px": "1px"}}>
                                                <div className="about-us-list">
                                                    <h3 className="title"><FiUser/>  {true ? "Modalidad": "Modality"} </h3>
                                                    <p style={{fontSize: "18px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "18px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span> 100% online
                                                    <span style={{padding: "3px", marginLeft: "7px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                                        <span className="pulsar"></span> {true ? "En vivo": "Live"}
                                                    </span>
                                                     <br/>
                                                        <div className="pt-2">
                                                        <span style={{"color":"slateblue", "fontSize": "18px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>  {true ? "Mentorías 1:1" : "Receive Mentoring 1 to 1"}<br/>
                                                        </div>
                                                    </p>
                                                </div>
                                            </div>


                                            <div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{marginTop: window.type_device? "10px": "1px"}}>
                                                <div className="about-us-list">
                                                    <h3 className="title"><FiRadio/> {true ? "Dedicación": "Dedication"} </h3>
                                                    <p style={{fontSize: "18px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "18px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>

                                                     2 sesiones semanales
                                                     <br/>
                                                 
                                                     <div className="pt-2">
                                                        <span style={{"color":"slateblue", "fontSize": "18px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>  
                                                        4 hrs. en vivo cada semana
                                                        <br/>
                                                        </div>
                                                    </p>
                                                </div>
                                            </div>

                                      

                                            <div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{marginTop: window.type_device? "10px": "1px"}}>
                                                <div className="about-us-list">
                                                    <h3 className="title"><GiPlatform/>  Plataforma </h3>
                                                    <p style={{fontSize: "18px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "18px"}}> <FiCheckCircle style={{fontSize: "18px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                        Acceso de por vida.
                                                    </p>
                                                </div>
                                            </div>




                                            <div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{marginTop: window.type_device? "10px": "1px"}}>
                                                <div className="about-us-list">
                                                    <h3 className="title"> {true ? "Requisitos": "Requirements"} </h3>
                                                    <p style={{fontSize: "20px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    <a style={{"cursor": "pointer", "text-decoration": "underline"}} onClick={this.openModal2}> {true ? "Ver": "View"}</a>

                                                    </p>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{marginTop: window.type_device? "10px": "1px"}}>
                                                <div className="about-us-list">
                                                    <h3 className="title"> 
                                                        {true ? "Temario": "Syllabus"} </h3>
                                                    <p style={{fontSize: "20px"}}>

                    <button
onClick={() => {
    this.setState({isOpenSuscribe: true});
    window.fbq('track', 'AddToCart', {
        value: 497,                 // Valor total de la compra
        currency: 'USD',               // Moneda utilizada (ISO 4217)
        content_type: 'product',       // Tipo de contenido
        content_ids: ['bootcamp'],  // ID del producto o identificador
        content_name: 'bootcamp'
      });
}}
                    id="enroll_bootcamp_syllabus"
                    style={{fontFamily: "'Poppins',sans-serif",  lineHeight: "28px", fontSize: "21px", marginBottom: "13px", padding: "15px 30px", textTransform: "uppercase" }}
                    className="btn-efect">
                    <FaRegFilePdf style={{fontSize: "23px", marginRight: "10px"}}/>
                    Descargar Temario
                     </button>

                                          
                                                    </p><br/>
                                                </div>
                                            </div>


                                            <div className="col-12" style={{marginTop: window.type_device? "10px": "1px"}}>
                                                <h3 className="title"><FaGraduationCap/>  Certificaciones Verificadas </h3>
                                                <div className="row justify-content-center">
                                                <div className="about-us-list col-6 col-md-2 col-lg-2"  >
                                                    <img onContextMenu={(e) => e.preventDefault()} alt="Certificado Web Full Stack Developer" src={"/assets/images/dojopy/CERTIFICADO-FS.png"}></img>
                                                </div>
                                           
                                                <div className="about-us-list col-6 col-md-2 col-lg-2"  >
                                                    <img onContextMenu={(e) => e.preventDefault()} alt="Certificado Frontend Developer" src={"/assets/images/dojopy/cert_frontend_react_2023.webp"}></img>
                                                </div>
                                                <div className="about-us-list col-6 col-md-2 col-lg-2"  >
                                                    <img onContextMenu={(e) => e.preventDefault()} alt="Certificado Backend Developer" src={"/assets/images/dojopy/cert_backend_python_2023.webp"}></img>
                                                </div>
                                                <div className="about-us-list col-6 col-md-2 col-lg-2"  >
                                                    <img onContextMenu={(e) => e.preventDefault()} alt="Certificado Cloud AWS Developer" src={"/assets/images/dojopy/cert_cloud_aws_2023.webp"}></img>
                                                </div>
                                           

                                                </div>
                                            </div>

                                    </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

}



 {/* Start About Area */}
 {/* <div data-aos="fade-left"  className="about-area ptb--60 bg_color--5" id="backend">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-md-6" style={{textAlign: "center"}}>

                                    <div className="thumbnail">
                                        <img height={window.type_device ? "290px": "450px"} src="/assets/images/dojopy/adds_fullstack.png" alt="Bootcamp Full Stack Web"/>
                                    </div>


                                </div>


                                <div className="col-md-6">
                                 
                                {
                                            <div className="py-3"  id="formulario" data-aos="fade-up">
                                            <div className="contact-form--1">
                        <div className="container" style={{maxWidth: "570px"}}>
                            <div className="align-items-center justify-content-center">
                                <div>

                                <div style={{textAlign: "-webkit-center"}} className="form-wrapper">
                       

                                <h2 style={{
                                    marginTop: '15px',
                                    textAlign: "left",
                                    fontSize: '38px',
                                    fontWeight: 'bold',
                                    fontFamily: "'Poppins',sans-serif"
                                }} className="title">
        Explora Tu Potencial en el Mundo Tech 🚀 
                                </h2>

                     <form onSubmit={this.onSubmit}>
                        <div className="row p-1">
                        <div className="col-12 text-center p-1" >

                        <label className="col-12 text-left font-weight-bold px-1 text-black" htmlFor="nameDojo">
                        <span style={{fontSize: "18px", display: "inline-block", marginBottom: "5px"}}>
                        👋 ¡Hola! ¿Cuál es tu nombre?
                        </span>
                            <input
                            id='nameDojo'
                            style={{marginBottom: "5px", backgroundColor: 'white'}}
                            type="text"
                            name="name"
                            value={this.state.Name}
                            onChange={(e) => this.setState({ Name: e.target.value})}
                            placeholder= {"Nombre"}
                            />
                        </label>

                        <label className="col-12 text-left font-weight-bold px-1 text-black mt-3" htmlFor="emailDojo">
                            <span style={{fontSize: "18px", display: "inline-block", marginBottom: "5px"}}>
                            📧 Ingresa tu Correo Electrónico
                            </span>
                            <input
                            style={{marginBottom: "5px", backgroundColor: 'white'}}
                            type="email"
                            name="email"
                            id="emailDojo"
                            value={this.state.Email}
                            onChange={(e) => this.setState({ Email: e.target.value})}
                            placeholder="Email"
                            />
                        </label>


<div className="text-left mt-3" >
    <b style={{fontSize: "18px", marginLeft: "1px"}}>
    📱 Número de WhatsApp
    </b>
    
</div> 
<div className="d-flex justify-content-center px-1">

<div className="mt-2 mx-1">
    <Selectrix
    className="pais-inscription bg-white"
    height={300}
    onRenderOption={this.onRenderOption}
    onRenderSelection={this.onRenderSelection}
    placeHolderInside={true}
    placeholder={"País"}
    customScrollbar={true}
    searchable={false}
    materialize={false}
    defaultValue={this.state.countryCode}
    options={KeyCountry}
    />

</div>

<div style={{width: "80%"}}>
<label htmlFor="Phone">
    
    <input
    style={{background: "white"}}
    type="text"
    name="phone"
    id="Phone"
    value={this.state.Phone}
    onChange={(e) => {
        this.setState({ Phone: e.target.value });
    }}
    placeholder="WhatsApp"
    />
 </label>
</div>

</div>


                                <button className="btn-efect-live" type="submit" name="submit" id="mc-embedded-subscribe-"
                                    style={{color: "#1f1f25", fontFamily: "Poppins,sans-serif", width: "100%", fontSize: "25px", fontWeight: "bold", textTransform: "uppercase"}}>
                                {this.state.loadingEnroll  &&
                                                <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                style={{fontSize: "20px", marginRight: "5px"}}
                                                />}
                                Recibir Más Información 🚀
                                </button>

                                <div className="col-12 mt-2 text-center" style={{color: "white", fontSize: "13px", textAlign: "center"}}>
                    <label htmlFor="checkNotify" style={{float: "left", color: "#1f1f25"}}>
                        <input
                            style={{width: 16, float: "left", marginTop: "-10px", marginRight: "9px", marginBottom: "1px"}}
                            defaultChecked={true}
                            type="checkbox"
                            name="checkNotify"
                            id="checkNotify"
                        />
                        <span className="">{true ? "Protección de datos y privacidad.": "Data protection and privacy."} </span>
                    </label>
                    </div>

                        </div>
                        </div>

                        </form>

                        </div>

                        </div>
                        </div>
                        </div>
                        </div>
                                            </div>
                                }               



                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End About Area */}




      

                {this.state.showWeb && 
    <div data-aos="fade-right" className="about-area ptb--120 bg_color--5" id="benefits" style={{marginTop: "-50px"}}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">

                                <div className="col-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">
                                            {true ? "¿Para quién es este Bootcamp?": "Who is this Bootcamp for?"}
                                            </h2>

                                        </div>


                                    {window.type_device ?
                                        <TouchCarousel
                                        component={Container2}
                                        cardSize={cardSize2}
                                        cardCount={data2.length}
                                        cardPadCount={cardPadCount}
                                        loop={true}
                                        autoplay={2e3}
                                        renderCard={this.renderCard2}
                                        /> :

                                        <div className="row mt--30">
                                        <div className="col-12 col-md-6 col-lg-3">
                                            <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "25px", "background": "white"}}>
                                                <h5>
                                                {true ? "Quieres cambiar de carrera": "Do you want to change careers?"}
                                                </h5>
                                                <img alt="Quieres cambiar de carrera" height="150px" src="/assets/images/icons/change.webp"></img>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-3">
                                            <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "25px", "background": "white"}}>
                                                <h5>
                                                {true ? "Mejora tu currículo con nuevas habilidades": "Enhance your resume with new skills"}
                                                </h5>
                                                <img alt="Mejora tu currículo con nuevas habilidades" height="150px" src="/assets/images/icons/curriculum.webp"></img>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-3">
                                            <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "25px", "background": "white"}}>
                                                <h5>
                                                {true ? "Emprendedores y visionarios": "Entrepreneurs and visionaries"}
                                                </h5>
                                                <img alt="Emprendedores y visionarios" height="150px" src="/assets/images/icons/idea.webp"></img>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-3">
                                            <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "25px", "background": "white"}}>
                                                <h5>
                                                    {true ? "Si aún no sabes sobre tecnologías web": "If you still don't know about web technologies"}
                                                </h5>
                                                <img alt="Si aún no sabes sobre tecnologías web" height="150px" src="/assets/images/dojopy/web.webp"></img>
                                            </div>
                                        </div>

                                    </div>
                                    }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    }




{this.state.showWeb && 
    <div data-aos="fade-right" className="about-area ptb--120 bg_color--5" id="benefits" style={{marginTop: "-100px"}}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">

                                <div className="col-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title mb-0">
                                            ¿Qué es el Desarrollo <br/> Full Stack?
                                            </h2>

                                            <div style={{width: window.type_device ? "200px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>

                                        </div>
                                        <div>
                                            <p>
                                            ¿Imaginas tener la habilidad de crear aplicaciones web y móviles desde cero? ¡Conviértete en un desarrollador Full Stack y domina el arte de diseñar experiencias digitales irresistibles! Aprenderás a manejar todo el proceso de desarrollo: desde el diseño visual y la lógica del servidor hasta la gestión de bases de datos. Con estas habilidades, no solo construirás sitios web y aplicaciones interactivas, sino que también abrirás la puerta a un mundo lleno de oportunidades tecnológicas y recompensas profesionales.

                                            </p>

                                {this.state.countryCode === "US" ?
                                    <>
                                <h5>
                                    ¡El momento es ahora! La industria tecnológica en EE.UU está en auge, con más de 3,000,000 vacantes en TI por cubrir. Da el salto y sé parte de esta revolución digital. ¡Es tu oportunidad para destacar!
                                </h5>
                                    </> :
                                    <>
                                        <h5>
                                    ¡El momento es ahora! La industria tecnológica en América Latina está en auge, con más de 1,000,000 vacantes en TI por cubrir. Da el salto y sé parte de esta revolución digital. ¡Es tu oportunidad para destacar!
                                        </h5>
                                    </>
                                    }
                                         
                                        </div>

                               

                                    </div>
                                </div>

                   
                            </div>
                        </div>
                    </div>
                </div>
    }



{this.state.showWeb && 
    <div data-aos="fade-right" className="about-area ptb--120 bg_color--5" id="profile" style={{marginTop: "-100px"}}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">

                                <div className="col-12 col-md-9">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">
                                            {true ? "Un vistazo a tu futuro perfil ": "A look at your future profile"}
                                            </h2>
                                            <h3 className="title mt-4">
                                             {true ? "¿Qué trabajos podré conseguir?": "prestigious position"}
                                            </h3>
                                        </div>
                                        <div>
                                        <span className="bg-white m-1" style={{padding: "5px 7px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "18px"}}> {true ? "Desarrollador Web Full stack ": "Full stack web developer"} </span>
                                        <span className="bg-white m-1" style={{padding: "5px 7px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "18px"}}>{true ? "Desarrollador Frontend ": "Front End Developer"} </span>
                                        <span className="bg-white m-1" style={{padding: "5px 7px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "18px"}}> {true ? "Desarrollador Backend": "Backend Developer"} </span>
                                        <span className="bg-white m-1" style={{padding: "5px 7px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "18px"}}>{true ? "Desarrollador de Software ": "Software developer"} </span>
                                        <span className="bg-white m-1" style={{padding: "5px 7px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "18px"}}>{"Desarrollador de Apps Móviles"} </span>
                                        <span className="bg-white m-1" style={{padding: "5px 7px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "18px"}}>{"Desarrollador de E-commerce"} </span>
                                        <span className="bg-white m-1" style={{padding: "5px 7px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "18px"}}> {true ? "Desarrollador Cloud de AWS": "cloud developer"} </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                            <h3 className="title mt-4">
                                            {true ? "Perfil profesional al finalizar el Bootcamp": "Interesting activities"}
                                            </h3>
                                            <div>
                                                <p style={{fontWeight: "bold", fontSize: "18px", "margin-bottom": "10px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    Tendrás la capacidad de desarrollar, diseñar y prototipar interfaces Web modernas trabajando con  tecnologías de vanguardia,
                                                    lo cual te permitirá construir aplicaciones más complejas, dinámicas y escalables.
                                                </p>
                                               
                                                <p style={{fontWeight: "bold", fontSize: "18px", "margin-bottom": "10px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    Definir las funcionalidades requeridas y el alcance de las librerías externas de una aplicación Web.
                                                </p>
                                             
                                                <p style={{fontWeight: "bold", fontSize: "18px", "margin-bottom": "10px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    Construir interfaces Web fáciles de usar para el usuario.
                                                </p>

                                                <p style={{fontWeight: "bold", fontSize: "18px", "margin-bottom": "10px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    Desplegar e implementar aplicaciones Web en la nube.
                                                </p>

                                                <p style={{fontWeight: "bold", fontSize: "18px", "margin-bottom": "10px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    Desarrollar Software  para las empresas.
                                                </p>
                                                <p style={{fontWeight: "bold", fontSize: "18px", "margin-bottom": "10px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    Crear productos digitales geniales en equipo.
                                                </p>

                                                

                                            </div>
                                    </div>

                                    <div className="col-12 col-md-9">
                                            <h3 className="title mt-4">
                                             {true ? "Habilidades claves para el mercado laboral": "Key skills for the labor market"}
                                            </h3>
                                            <div>
                                            <TouchCarousel
                                                component={Container}
                                                cardSize={cardSize}
                                                cardCount={data.length}
                                                cardPadCount={cardPadCount}
                                                loop={true}
                                                autoplay={2e3}
                                                renderCard={this.renderCard}
                                                />
                                            </div>
                                    </div>


                                    <Tabs>
    <TabList style={{textAlign: "center"}}>
        
        {this.state.countryCode !== "US" &&
        <Tab style={{fontSize: "21px", padding: "10px 10px", fontWeight: "bold" }}>
            <MdPayments color="#2f6299"  style={{fontSize: "30px"}}/> Salario en Latinoamérica
        </Tab>
        }
        
        <Tab style={{fontSize: "21px", padding: "10px 10px", fontWeight: "bold" }}>
            <MdPayments color="#0000ff" style={{fontSize: "30px"}}/> Salario en Estados Unidos
            </Tab>
    </TabList>

    {this.state.countryCode !== "US" &&
        <TabPanel>
            <div className="row">
            <div className="col-12 col-md-8">
            <h3 className="title mt-4">
            {true ? "Salario alto y perspectivas seguras": "High salary and secure prospects"}
            </h3>
            <p style={{fontSize: "13px"}}>{true ? "Estos salarios mensuales promedio están tomados de Computrabajo y Glassdoor, el portal de empleo líder en Latinoamérica. Pero tú puedes ser mejor que el promedio.":
            "These average monthly salaries are taken from Indeed and Glassdoor, the leading job portal in the United States. But you can be better than average."} </p>
            <div style={{boxShadow: "3px 2px 6px 0px #808080b0"}} className="p-3 m-2 bg-white col-10 col-md-6">
                <h3 style={{fontWeight: "500"}}>{true ? this.state.salario_fullstack_jr: "$70,000 / year"}</h3>
                <p>{true ? "Desarrollador Full Stack Web Junior": "Junior Full Stack Web Developer"}  <span className="text-primary d-inline-block"> {true ? "después del Bootcamp": "after bootcamp"}  </span> </p>
            </div>
            <div style={{boxShadow: "3px 2px 6px 0px #808080b0"}} className="p-3 m-2 bg-white col-11 col-md-8">
                <h3 style={{fontWeight: "500"}}>{true ? this.state.salario_fullstack_md: "$90,000 / year"}</h3>
                <p>{true ? "Desarrollador Full Stack Web Middle ": "Full Stack Middle Web Developer"} <span className="text-primary d-inline-block">{true ? "en 1 año": "in 1 year"} </span> </p>
            </div>
            <div style={{boxShadow: "3px 2px 6px 0px #808080b0"}} className="p-3 m-2 bg-white col-12 col-md-10">
                <h3 style={{fontWeight: "500"}}>{true ? this.state.salario_fullstack_sr: "$120,000 / year"}</h3>
                <p>{true ? "Desarrollador Full Stack Web Senior": "Senior Full Stack Web Developer"}  <span className="text-primary d-inline-block">{true ? "en 2 años": "in 2 years"} </span> </p>
            </div>
        </div>
        <div className="col-12 col-md-4" style={{placeSelf: "center"}}>
        <img alt="El sector de la programacion web está prosperando" src="/assets/images/dojopy/top-thrending-2025.png"/>
        <img className="mt-2" alt="El sector de la programacion web está prosperando" src="/assets/images/dojopy/top-salario-tech-2025.png"/>

        </div>
            </div>

        </TabPanel>
    }
        <TabPanel>
            <div className="row">

        <div className="col-12 col-md-8">
            <h3 className="title mt-4">
            {true ? "Salario alto y perspectivas seguras": "High salary and secure prospects"}
            </h3>
            <p style={{fontSize: "13px"}}>{true ? "Estos salarios mensuales promedio están tomados de Glassdoor, el portal de empleo líder en Estados Unidos. Pero tú puedes ser mejor que el promedio.":
            "These average monthly salaries are taken from Indeed and Glassdoor, the leading job portal in the United States. But you can be better than average."} </p>
            <div style={{boxShadow: "3px 2px 6px 0px #808080b0"}} className="p-3 m-2 bg-white col-10 col-md-6">
                <h3 style={{fontWeight: "500"}}>{this.state.usa_salario_fullstack_jr}
                <ReactCountryFlag
                countryCode={'US'}
                svg
                style={{
                    marginLeft: "8px",
                    width: '1.2em',
                    height: '1.2em',
                    padding: '1px'
                }}
                title="país"
            />
                </h3>
                <p>{true ? "Desarrollador Full Stack Web Junior": "Junior Full Stack Web Developer"}  <span className="text-primary d-inline-block"> {true ? "después del Bootcamp": "after bootcamp"}  </span> </p>
            </div>
            <div style={{boxShadow: "3px 2px 6px 0px #808080b0"}} className="p-3 m-2 bg-white col-11 col-md-8">
                <h3 style={{fontWeight: "500"}}>{this.state.usa_salario_fullstack_md}
                <ReactCountryFlag
                countryCode={'US'}
                svg
                style={{
                    marginLeft: "8px",
                    width: '1.2em',
                    height: '1.2em',
                    padding: '1px'
                }}
                title="país"
            />
                </h3>
                <p>{true ? "Desarrollador Full Stack Web Middle ": "Full Stack Middle Web Developer"} <span className="text-primary d-inline-block">{true ? "en 1 año": "in 1 year"} </span> </p>
            </div>
            <div style={{boxShadow: "3px 2px 6px 0px #808080b0"}} className="p-3 m-2 bg-white col-12 col-md-10">
                <h3 style={{fontWeight: "500"}}>{this.state.usa_salario_fullstack_sr}
                <ReactCountryFlag
                countryCode={'US'}
                svg
                style={{
                    marginLeft: "8px",
                    width: '1.2em',
                    height: '1.2em',
                    padding: '1px'
                }}
                title="país"
            />
                </h3>
                <p>{true ? "Desarrollador Full Stack Web Senior": "Senior Full Stack Web Developer"}  <span className="text-primary d-inline-block">{true ? "en 2 años": "in 2 years"} </span> </p>
            </div>
        </div>
        <div className="col-12 col-md-4" style={{placeSelf: "center"}}>
        <img alt="El sector de la programacion web está prosperando" src="/assets/images/dojopy/top-thrending-2025.png"/>
        <img className="mt-2" alt="El sector de la programacion web está prosperando" src="/assets/images/dojopy/top-salario-tech-2025.png"/>
        </div>
        </div>
        </TabPanel>
    </Tabs>


                            </div>
                </div>
            </div>
        </div>

            }





{false && 
          <div data-aos="fade-right" className="about-area ptb--30 bg_color--5">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                            <div className="text-center col-12">
                                <h3 className="title"> {true ? "Tu portafolio de proyectos": "Your portfolio of projects"}</h3>
                            </div>

                                <div className="col-md-4 col-6">

                                    <div className="thumbnail zoom">
                                        <img alt="proyecto web personal" style={{maxHeight: "470px", maxWidth: "470px", transition: "0.2s"}} className="w-100" src="/assets/images/dojopy/project1.webp"/>
                                        <p style={{padding: "13px"}}>{true ? "Web Personal": "Personal website"} </p>
                                    </div>
                                </div>

                                <div className="col-md-4 col-6">
                                    <div className="thumbnail zoom">
                                        <img alt="proyecto Web para negocios" style={{maxHeight: "470px", maxWidth: "470px", transition: "0.2s"}} className="w-100" src={true ? "/assets/images/dojopy/project2.webp" : "/assets/images/dojopy/doctor-web-us.webp"} />
                                        <p style={{padding: "13px"}}>{true ? "Web para empresas": "Website for companies"} </p>
                                    </div>
                                </div>



                                <div className="col-md-4 col-12">
                                    <div className="thumbnail zoom">
                                        <img  alt="proyecto Web de Pedidos inspirada en Rappi" style={{maxHeight: "470px", maxWidth: "470px", transition: "0.2s"}} className="w-100" src={true ? "/assets/images/dojopy/project4.webp": "/assets/images/dojopy/amazon_project.png"}/>
                                        <p style={{padding: "13px"}}>{true ? <>Web de Pedidos inspirada en <b>Rappi.com</b> </>: <>Commerce website inspired by <b>Amazon.com</b> </>} </p>
                                    </div>
                                </div>

                            </div>




                        </div>
                    </div>
                </div>

    }





{this.state.showWeb && 
   <div data-aos="fade-right" className="about-area ptb--50 bg_color--5"  style={{marginTop: "1px", color: "#1f1f25"}}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                            <div className="text-center col-12">
                            <span style={{padding: "3px", marginLeft: "7px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                            <span className="pulsar"></span> {true ? "En vivo": "Live"}
                                                            </span>
                                <h2 className="title text-black" style={{padding: "5px"}}>
                                ¿Cómo funciona el Bootcamp?
                                    </h2>

                            </div>
                               <div className="p-3">
                                   <p>
                                   {true ? <>
                                    Cada semana tendrás <b>2 encuentros grupales en vivo</b> con el Instructor, al final de cada semana hay actividades prácticas, no te preocupes si te trabas en las actividades siempre tendrás asesorías personalizadas.
                                   <br/>
                                   En DojoFullStack proponemos un nuevo modelo educativo que incluye entornos de aprendizaje sincrónicos y asincrónicos con un enfoque que vincula la teoría y la práctica, mediante un aprendizaje activo.
                                   </>: <>
                                   Each week you will have <b>2 live group meetings</b> with the Instructor, at the end of each week there are practical activities, don't worry if you get stuck in the activities you will always have personalized advice.
                                    <br/>
                                    At DojoFullStack we propose a new educational model that includes synchronous and asynchronous learning environments with an approach that links theory and practice, through active learning.
                                   </> }
                                    </p>

                                    <div className="row mt--30">
                                        <div className="col-12 col-md-6 col-lg-3 mt-3">
                                            <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "25px", "background": "white"}}>
                                                <h4>
                                                {true ? "Conéctate a tu primera sesión ": "Connect to your first session"}

                                                            <span style={{padding: "3px", marginLeft: "7px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                                            <span className="pulsar"></span> {true ? "En vivo": "Live"}
                                                            </span>
                                                </h4>
                                                <img alt="Conéctate a tu primera sesión en vivo" height="190px" src="/assets/images/dojopy/live_zoom.webp" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-3 mt-3">
                                            <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "25px", "background": "white"}}>
                                                <h4>
                                                {true ? "Actividades asíncronas en la Plataforma.": "Practice with new challenges and activities every week"}
                                                </h4>
                                                <img style={{borderRadius: "15px"}}  alt="Practica con nuevos retos y actividades cada semana" height="130px" src="/assets/images/dojopy/plataforma_bootcamp.webp" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-3 mt-3">
                                            <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "25px", "background": "white"}}>
                                                <h4>
                                                Mentorías 1:1
                                                </h4>
                                                <img alt="Recibe asesoría personalizada 1 a 1" height="135px" src="/assets/images/dojopy/live-mentoring-1-1.png" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-3 mt-3">
                                            <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "25px", "background": "white"}}>
                                                <h4>
                                                Comparte tus proyectos,
                                                así todos aprenden más
                                                </h4>
                                                <img alt="Presenta y comparte tus proyectos, así todos aprenden más" height="130px" src="/assets/images/dojopy/programming _.webp"></img>
                                            </div>
                                        </div>
                                    </div>
                               </div>

                            </div>
                        </div>
                    </div>
                </div>

}


{this.state.showWeb && 
                <div data-aos="fade-right" className="about-area ptb--20 bg_color--5" id="fechas">

                <div className="about-wrapper" style={{paddingTop: "20px"}} >
                <div className="container">

                            <div className="section-title mb-4">
                            <h2 className="title text-center" style={{paddingBottom: "8px" }}>
                            Fecha de Inicio <span style={{lineHeight: "20px", display: "inline-block", marginLeft: "5px", padding: "3px", "background-color": "tomato", "border-radius": "7px", "padding": "8px 13px", "color": "white", "font-size": "17px"}}>
                        <span className="pulsar"></span> En vivo
                    </span>
                            </h2>
                            </div>
                      


                        <div className="col-12">
                                 {this.renderFechas()}
                        </div>




                    </div>
                    </div>
                    </div>
    }






{this.state.showWeb && 
                <div data-aos="fade-right" className="about-area ptb--60 bg_color--5" id="frontend">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">

                                <div className="col-12 mt-3 mb-3">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h3 className="title pb-3">{true ? "¿Cuáles serán los Módulos?": "Main modules"}</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img height={window.type_device ? "250px": "350px"} src="/assets/images/dojopy/7.webp" alt="primer módulo web frontend"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                 
                                            <h2 className="title mb-0">WEB FRONTEND</h2>
                                            <div style={{width: window.type_device ? "200px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>

                                            <p>
                                                <b> {true ? "Conviértete en quien crea las interfaces que los usuarios aman.": "Become the one who creates the interfaces that users love."} </b>
                                            </p>

                                            {!this.state.showMoreC &&
                                            <div className="col-12 text-left">
                                                <button
                                                onClick={() => this.setState({showMoreC: true}) }
                                                type="submit"
                                                name="submit"
                                                id="mc-embedded-subscribe-"
                                                style={{ marginTop: "5px", marginBottom: "5px", fontSize: "18px",
                                                "border-radius": "20px",
                                                "border": "2px solid #6a5acd",
                                                "padding": "9px 20px"

                                            }}
                                                >
                                                 {true ? "Mostrar más": "Show more"} <br/>
                                                <AiOutlineDown style={{padding: "5px", fontSize: "30px"}}/>
                                                </button>
                                            </div>}
                                            </div>

                            {this.state.showMoreC &&
                            <>
                            <p>
                            Cada vez más empresas buscan programadores frontend capaces de comprender y aportar aplicaciones web con interfaces de usuario amigables y modulares para lograr un desarrollo ágil y una usabilidad adecuada. <br/>

Este perfil se especializa en diseñar y construir todo aquello que se ve y con lo que una persona interactúa al entrar a una aplicación web: botones, imágenes, formularios, etc. Es una habilidad fundamental para garantizar impacto en la experiencia de los usuarios con los productos digitales. <br/>

En este programa aprenderás desde los fundamentos de la programación hasta el lanzamiento de aplicaciones web con las últimas tecnologías del mercado, como ReactJS.
                                            </p>
                                    <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> HTML</h4>

                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> CSS/SASS</h4>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Javascript</h4>

                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> React JS</h4>

                                                </div>
                                            </div>
                                        </div>

                                        </>}
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

}



{this.state.showWeb && 
                <div data-aos="fade-left"  className="about-area ptb--60 bg_color--5" id="backend">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img height={window.type_device ? "250px": "350px"} src="/assets/images/dojopy/8.webp" alt="segundo módulo web Backend"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                               
                                            <h2 className="title mb-0">WEB BACKEND</h2>
                                            <div style={{width: window.type_device ? "200px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>

                                            <p>
                                                <b>{true ? "Conviértete en quien crea la lógica detrás de todas las aplicaciones web": "Become the one who creates the logic behind all web applications"} .</b>
                                            </p>

                                        </div>


                                        {!this.state.showMoreD &&
                                            <div className="col-12 text-left">
                                                <button
                                                onClick={() => this.setState({showMoreD: true}) }
                                                type="submit"
                                                name="submit"
                                                id="mc-embedded-subscribe-"
                                                style={{ marginTop: "5px", marginBottom: "5px", fontSize: "18px",
                                                "border-radius": "20px",
                                                "border": "2px solid #6a5acd",
                                                "padding": "9px 20px"

                                            }}
                                                >
                                                {true ? "Mostrar más": "Show more"} <br/>
                                                <AiOutlineDown style={{padding: "5px", fontSize: "30px"}}/>
                                                </button>
                                            </div>}

                {this.state.showMoreD &&
                <>


                                            <p>
        Cada vez más empresas buscan programadores backend capaces de comprender y crear aplicaciones web que brinden todo lo que estas necesiten para
        dar respuesta a sus usuarios.<br/>

        Este perfil se especializa en la creación de APIs, bases de datos y arquitecturas escalables. Es decir, en todo aquello que
        no se ve, pero que es fundamental para el funcionamiento de las aplicaciones y sitios web que usamos todos los días.<br/>

        En este bootcamp aprenderás desde los fundamentos de la programación hasta el lanzamiento de aplicaciones web sólidas haciendo uso de las últimas tecnologías
        de la industria.
                                            </p>


                                    <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Node JS</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Python</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> REST / RESTfull / APIs</h4>
                                                </div>
                                            </div>
                                    
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Autenticación JWT</h4>
                                                </div>
                                            </div>
                                        </div>
                                        </>}

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
}


{this.state.showWeb && 
                <div data-aos="fade-left"  className="about-area ptb--60 bg_color--5" id="backend">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-80" height={window.type_device ? "250px": "350px"} src="/assets/images/dojopy/9.webp" alt="tercer módulo bases de datos"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                         
                                            <h2 className="title mb-0">{true ? "BASES DE DATOS": "DATABASES"} </h2>
                                            <div style={{width: window.type_device ? "200px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>

                                            <p>
                                         <b>
                                         Construye tus propios modelos de bases de datos con las mejores prácticas desde cero
                                            </b>  </p>

                                        </div>

                                        {!this.state.showMoreE &&
                                            <div className="col-12 text-left">
                                                <button
                                                onClick={() => this.setState({showMoreE: true}) }
                                                type="submit"
                                                name="submit"
                                                id="mc-embedded-subscribe-"
                                                style={{ marginTop: "5px", marginBottom: "5px", fontSize: "18px",
                                                "border-radius": "20px",
                                                "border": "2px solid #6a5acd",
                                                "padding": "9px 20px"

                                            }}
                                                >
                                                {true ? "Mostrar más": "Show more"} <br/>
                                                <AiOutlineDown style={{padding: "5px", fontSize: "30px"}}/>
                                                </button>
                                            </div>}

            {this.state.showMoreE &&
                <>

                                            <p>
        Antes de utilizar cualquier tecnología para bases de datos debes entender los fundamentos de diseño y modelado
         requeridos para que tengas la mejor arquitectura posible.

                                            </p>
                                    <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Fundamentos</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Bases de Datos Relacionales (PostgreSQL)</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Bases de Datos no Relacionales (mongoDB/Mongoose)</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Cloud Databases</h4>
                                                </div>
                                            </div>
                                        </div>
                                        </>}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    }





{false && this.state.showWeb && 
    <div data-aos="fade-left"  className="about-area ptb--60 bg_color--5" id="">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-80" height={window.type_device ? "250px": "350px"} src="/assets/images/dojopy/BOOTCAMP-apps-r.png" alt=""/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                                          
                                                          
                                            <h2 className="title mb-0">
                                                Desarrollo de Apps Móviles
                                            </h2>
                                            <div style={{width: window.type_device ? "200px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>
                                            <p>
                                        <b>
                                        🚀 ¡Crea apps increíbles con React Native! Aprende a desarrollar aplicaciones móviles multiplataforma para iOS y Android con una sola base de código. Diseña experiencias rápidas y modernas mientras optimizas tiempo y recursos. ¡El futuro del desarrollo móvil está en tus manos! 🌟
                                    
                                        </b>  </p>


                                        </div>
              
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    }




{this.state.showWeb && 
    <div data-aos="fade-left"  className="about-area ptb--60 bg_color--5" id="">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-80" height={window.type_device ? "250px": "350px"} src="/assets/images/dojopy/10.webp" alt="Desplieque en Amazon Web Services"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                                          
                                                          
                                            <h2 className="title mb-0">{true ? "SERVICIOS EN LA NUBE CON AWS": "DEPLOYMENT IN THE AWS CLOUD"}</h2>
                                            <div style={{width: window.type_device ? "200px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>
                                            <p>
                                        <b>{true ? "Sube tus aplicaciones web en la moderna arquitectura de AWS.": "Upload your web applications on the modern architecture of AWS."}  </b>  </p>

                                        {!this.state.showMoreF &&
                                            <div className="col-12 text-left">
                                                <button
                                                onClick={() => this.setState({showMoreF: true}) }
                                                type="submit"
                                                name="submit"
                                                id="mc-embedded-subscribe-"
                                                style={{ marginTop: "5px", marginBottom: "5px", fontSize: "18px",
                                                "border-radius": "20px",
                                                "border": "2px solid #6a5acd",
                                                "padding": "9px 20px"

                                            }}
                                                >
                                                {true ? "Mostrar más": "Show more"} <br/>
                                                <AiOutlineDown style={{padding: "5px", fontSize: "30px"}}/>
                                                </button>
                                            </div>}

                                        </div>

                {this.state.showMoreF &&
                <>
                                        <p>
        AWS es una de las principales plataformas de servicios de computación
        en la nube a nivel mundial, AWS proporciona un amplio conjunto de servicios de
        infraestructura tal como potencia de cómputo, opciones de almacenamiento, redes y bases de datos ofertados
        con una utilidad bajo demanda, disponibles en cuestión de segundos y pagando solo por lo que utiliza. <br/>
                                            </p>

                                    <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Bases de datos</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Servidor privado</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Almacenamiento</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> Arquitectura Serverless</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    }



{this.state.showWeb && 
    <div data-aos="fade-left"  className="about-area ptb--60 bg_color--5" id="">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img height={window.type_device ? "250px": "350px"} src="/assets/images/dojopy/startups_logo.webp" alt="proyecto startups"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <span style={{padding: "3px", marginLeft: "7px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                                            <span className="pulsar"></span> {true ? "En vivo": "Live"}
                                                            </span>
                                            <h2 className="title mb-0">{true ? "PROYECTOS WEB FULL STACK ": "FULL STACK PROJECT"} </h2>
                                            <div style={{width: window.type_device ? "200px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>
                                            <p>
                                        <b> {true ? "¿Quieres aprender a crear desde cero una Startup o producto digital?": "Do you want to learn how to create a Startup or digital product from scratch?"}  </b>  </p>
                                        {!this.state.showMoreG &&
                                            <div className="col-12 text-left">
                                                <button
                                                onClick={() => this.setState({showMoreG: true}) }
                                                type="submit"
                                                name="submit"
                                                id="mc-embedded-subscribe-"
                                                style={{ marginTop: "5px", marginBottom: "5px", fontSize: "18px",
                                                "border-radius": "20px",
                                                "border": "2px solid #6a5acd",
                                                "padding": "9px 20px"

                                            }}
                                                >
                                                {true ? "Mostrar más": "Show more"} <br/>
                                                <AiOutlineDown style={{padding: "5px", fontSize: "30px"}}/>
                                                </button>
                                            </div>}
                            {this.state.showMoreG && (

                                         <p>
                Nosotros somos el único Bootcamp que propone como proyecto final desarrollar un MVP de una startup o empresa digital
                aprende a crear una plataforma como Rappi o Airbnb con nuestro equipo de mentores full stack desde cero. <br/>
                💪 tú puedes ser el próximo fundador de la siguiente red social o marketplace innovadora 🚀.
                                            </p> )}
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    }







{this.state.showWeb && this.state.countryCode.toLocaleUpperCase() !== "US" && 
 <div className="about-area ptb--60 bg_color--5" id="platform">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img height="290px" src="/assets/images/dojopy/banner_ingles.webp" alt="ROGRAMA DE EMPLEO y ACELERACIÓN LABORAL"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                     
                                            <h2 className="title" style={{textTransform: "uppercase"}}>
                                               Ingles para Desarrolladores
                                            </h2>

                                            <span style={{lineHeight: "21px", display: 'inline-block', padding: "5px",marginLeft: "5px", "background-color": "slateblue", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                                Programa Optativo
                                                </span>

                                            <div style={{width: window.type_device ? "250px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>
                                          
                                            <p>
                                            ¡Nuevo Programa de Inglés para Desarrolladores! Este programa interactivo está diseñado para ayudarte a dominar el inglés técnico, mejorando tu capacidad de comunicación y acceso a recursos cruciales en el mundo del desarrollo web. A través de clases dinámicas, talleres temáticos y grupos de conversación, estarás mejor preparado para enfrentar desafíos en tu carrera y aprovechar nuevas oportunidades en un entorno global. ¡Únete a nosotros y lleva tus habilidades al siguiente nivel!
                                            <br/> <br/>
                                            <b>
                                            🚀 Con este programa, también tendrás la oportunidad de trabajar para empresas de Estados Unidos y multiplicar tu salario hasta 5 veces.

                                            </b>

                                            </p>

                                          
                                          
                                </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    }


{this.state.countryCode !== "PE" && this.state.showWeb && 
 <div className="about-area ptb--60 bg_color--5" id="platform">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img height="250px" src="/assets/images/dojopy/TALENTO_search.webp" alt="ROGRAMA DE EMPLEO y ACELERACIÓN LABORAL"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                     
                                            <h2 className="title" style={{textTransform: "uppercase"}}>
                                               {true ? "Programa y Garantía de Empleabilidad": "EMPLOYMENT PROGRAM"}
                                            </h2>


                                            <div style={{width: window.type_device ? "250px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>
                                          
                                            <p>
                                            Estamos tan seguros de la calidad de nuestra formación que ofrecemos una garantía de empleabilidad.
                                            Creemos en el poder de nuestro programa y en tu potencial.
                                            </p>

                                            <p>
                                                Apoyo personalizada al finalizar el Bootcamp, recibirás una asesoría personalizada 1 a 1. Contarás con un coach especializado que te guiará en tu búsqueda de empleo, ayudándote a alcanzar tus objetivos profesionales.
                                            </p>

                                            <p>
                                                <b>
                                                En caso de que no logres obtener un empleo en los 12 meses posteriores a la finalización del Bootcamp,
                                                ¡Te reembolsaremos el 100% del dinero invertido! 
                                                </b>
                                     
                                            </p>

                                           
                                          
                                </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    }



{this.state.showWeb && 
                <Plataforma langUS={this.state.langUS} />
}

{/* 
                <div data-aos="fade-right" className="about-area ptb--50 bg_color--5" id="bonus2" style={{marginTop: "1px"}}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                             
                                <div className="col-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <img height={"125px"} src="/assets/images/dojopy/bonus_logo.webp"  />
                                            <h2 className="title" style={{fontSize: "38px", display: 'inline-block'}}>
                                            AMPLÍA TUS HABILIDADES CON BONUS EXCLUSIVOS
                                            </h2>
                                        <p>
                                        ¡Descubre los increíbles bonus que incluye nuestro bootcamp Full Stack! Además de brindarte una formación completa en desarrollo web, te ofrecemos la oportunidad de potenciar tus habilidades con tres cursos adicionales que te llevarán al siguiente nivel. 
                                        </p>
                                        <p>
                                        <b>Bonus exclusivo para aquellos que se inscriban anticipadamente antes de la fecha estipulada.</b>

                                        </p>
                                        <div>
                <a target="_blank" href={"https://https://drive.google.com/file/d/1yS-YNYhpneiN5AHkpGrAUkEdmgu1buXc/view?usp=sharing"}>
                <button
                style={{lineHeight: "20px", fontSize: "18px", marginBottom: "13px", padding: "15px 30px" }}
                className=" btn-efect">
                    <FiPackage style={{marginRight: "5px", fontSize: "20px"}} />
                VER LOS BONUS
                </button>
                </a>
                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

{this.state.showWeb && 
                <div data-aos="fade-right" className="about-area ptb--80 " id="methodology">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">


                                 {window.type_device ? (
                                        <div className="col-12" style={{textAlign: "-webkit-center", marginLeft: "-45px", marginBottom: "-40px"}}>
                                            <ReactPlayer
                                            muted={true}
                                            loop={true}
                                            url={"/assets/video/demo_lite_1.mp4"}
                                            playing={true}
                                            width={400}
                                            height={250}
                                                />
                                        </div>
                                    ):(
                                        <div className="col-12" style={{textAlign: "-webkit-center"}}>
                                        <ReactPlayer
                                        muted={true}
                                        loop={true}
                                        url={"/assets/video/demo_lite_1.mp4"}
                                        playing={true}
                                        width={520}
                                        />
                                        </div>
                                    )}


                                <div className="col-12" style={{textAlign: "-webkit-center", paddingTop: "30px"}}>
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                        {window.type_device ? (
                                            <h2>Aprende 100% online 
                                                 <span style={{lineHeight: "21px", display: 'inline-block', padding: "3px",marginLeft: "7px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "16px"}}>
                                                            <span className="pulsar"></span>  En vivo
                                                            </span>
                                                 </h2>
                                        ): (
                                            <TypingAnimation2/>
                                        )}

                                        </div>

                                        <h4 style={{fontSize: "18px"}}>
                                             En DojoFullStack proponemos un nuevo modelo educativo que incluye entornos de aprendizaje sincrónicos y asincrónicos con un enfoque que vincula la teoría y la práctica, mediante un aprendizaje activo.
                                            </h4>

                                    <div className="row mt--30">

                                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> 
                                                    100% online  <span style={{lineHeight: "21px", display: 'inline-block', padding: "3px",marginLeft: "7px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "17px"}}>
                                                            <span className="pulsar"></span>  En vivo
                                                            </span>
               
                                                            
                                                    </h4>
                                                </div>
                                            </div>


                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h4 className="title"><FiCheck style={{"color": "slateblue"}}/>
                                                    Sesiones grupales, Mentorías 1:1
                                                    </h4>

                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="about-us-list">
                                                    <h4 className="title"><FiCheck style={{"color": "slateblue"}}/>
                                                    {true ? "Aprende, comparte y crea proyectos con miembros de todo el mundo.": "Learn, share and create projects with members from all over the world."}
                                                    </h4>
                                                </div>

                                            
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
    }





                <div>
            </div>




            {this.state.showWeb &&
                <div className="rn-pricing-table-area ptb--40 bg_color--5" data-aos="fade-right" id="empezar">



<div className="container px-1" >
<div className="row" style={{justifyContent: "center", textAlign: "-webkit-center"}}>




<a href={this.state.linkPayUSD} >


<div className="col-12 mt-1" style={{paddingBottom: "13px", paddingRight: "15px", paddingLeft: "15px", maxWidth: "490px", marginRight: window.type_device ? "auto": "1px" }} >

<div  onClick={ () => window.fbq('track', 'AddToCart', {
                value: 497,                 // Valor total de la compra
                currency: 'USD',               // Moneda utilizada (ISO 4217)
                content_type: 'product',       // Tipo de contenido
                content_ids: ['bootcamp'],  // ID del producto o identificador
                content_name: 'bootcamp'
            })}   className="rn-pricing" style={{boxShadow: "rgba(140, 82, 255, 0.9) 4px 4px 15px 3px", border: "none", background: "linear-gradient(to right,rgb(74, 19, 122),rgb(29, 8, 70))", borderRadius: "20px"}} >
    <div className="pricing-table-inner">

        <div className="pricing-header pb-0 mb-2 mt-1">
                

        <h2 className="text-white p-0 m-0">Bootcamp
          </h2>

        <div className="mx-auto mt-1" style={{width: window.type_device ? "250px": "200px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>
        <span style={{lineHeight: "20px", display: "inline-block", marginLeft: "7px", padding: "3px", "padding-top": "4px","padding-bottom": "4px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                        <span className="pulsar"></span> En vivo
                    </span>
        <h2 className="text-white" style={{fontSize: window.type_device ? "38px": ""}}>Web Full Stack</h2>

      

        <p>
            <ul style={{lineHeight: "33px", textAlign: "left", fontFamily: "'Poppins',sans-serif"}}>
            <li style={{fontSize: "18px", color: "white"}}> <b> Clases en vivo durante 12 meses</b>  </li>
            <li style={{fontSize: "18px", color: "white"}}> <b> Plataforma con múltiples canales de comunicación.</b>  </li>

                {this.state.countryCode !== "PE" &&  <>  <li style={{fontSize: "18px", color: "white"}}> <b>Garantía de Empleo 100% 💼</b>  </li>  </> }
               
                <li style={{fontSize: "18px", color: "white"}}> <b> Programa de ingles para Devs </b>  </li>
                <li style={{fontSize: "18px", color: "white"}}> <b>  Mentoría Personalizada 1:1 👨‍🏫</b>  </li>
                <li style={{fontSize: "18px", color: "white"}}> <b> Comunidad activa y soporte profesional.</b>  </li>


            </ul>
    
        </p>

        <hr style={{borderTop:  "5px solid white"}} />

       

        <h3 className="text-white mt-2 mb-0">
        Pago Único
        </h3>

            <span style={{color: "white", fontSize: "18px"}}>Antes: </span>

            <span style={{color: "white", display: "inline-block"}}>
            <del style={{color: "white", fontSize: "23px"}}>
            &nbsp; {this.state.price_completo_regular_2} &nbsp;
            </del>
            </span>


            <h3 className="pricing" style={{margin: "10px"}} >
            <span style={{color: "white", fontSize: "18px"}}>Ahora: </span>

            {this.renderPrice(this.state.price_completo_2)}
            </h3>


            <div className="slide-btn" style={{marginTop: "20px", textAlign: "center"}}>
        <a href={this.state.linkPayUSD} >
                <button
                onClick={this.TrackEventConversion}
                id="enroll_bootcamp_2"
                style={{fontFamily: "'Poppins',sans-serif",  lineHeight: "28px", fontSize: "25px", marginBottom: "13px", padding: "15px 30px", textTransform: "uppercase" }}
                className="btn-efect">
                <GoRocket style={{fontSize: "23px", marginRight: "10px"}}/>
                ¡Comienza tu carrera ahora!
                 </button>
                </a>


        </div>

        <p style={{fontStyle: "italic", fontSize: "16px", color: "white", marginTop: "15px"}}>
            Aprovecha esta oportunidad única para transformar tu carrera. <br/> ¡Antes de que se agoten!
        </p>


        </div>

    </div>
</div>


</div>
</a>



                            </div>


                            <div className="about-us-list row mx-auto" style={{maxWidth: "900px"}}>
                                <div className="col-12 col-md-1 text-center my-2">
                                     <img width={window.type_device ? "65px": "85px" }  className="" src="/assets/images/dojopy/garantia-cash.png" style={{cursor: "pointer"}}/>
                                </div>
                                <div className="col-12 col-md-11 px-0">
                                    <h4 className="title" style={{fontStyle: "italic", fontWeight: "normal", textAlign: window.type_device ? "center": "left", fontSize: "19px"}}>
                                    Ofrecemos una garantía de satisfacción de 30 días: si nuestro Bootcamp Full Stack no cumple con tus expectativas, te reembolsaremos el 100% de tu inversión.
                                    </h4>
                                </div>

                            </div>
                          

                        </div>
                    </div>
    }








{this.state.showWeb && 
    <>
     <div style={{marginTop: "20px"}} className="section-title service-style--3 text-center mb--25 mb_sm--0 text-center">
                    <h2 className="title">{true ? "Testimonios": "Testimonials"} </h2>
                </div>

                <div className="rn-testimonial-area bg_color--1 ptb--80">
                    <div className="container">
                        <TestimonialOne langUS={this.state.langUS}  country={this.state.countryCode} />



    
                        <div style={{backgroundColor: "white", padding: "0", textAlign: "left"}}>
                        
                        <div className="d-flex my-3" style={{borderRadius: "15px", boxShadow: "5px 2px 9px 9px #dee2e6", padding: "5px", alignItems: "flex-start"}}>
                            <img className="ml-1" style={{borderRadius: "30px", marginRight: "5px"}} height="65px"
                            src="/assets/images/dojopy/019209102.png" 
                            />
                            <div className="ml-2 mt-2">
                              <h5 style={{fontStyle: "italic"}} className="my-0">Javier L.
                              <FaFacebookSquare style={{marginLeft: "5px", fontSize: "20px", color: "#1877F2"}}/>
                        
                                <span style={{marginLeft: "5px", fontSize: "13px", color: "gray"}}></span>
                              </h5>
                              <p className="my-0"><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/></p>
                        
                              <p style={{fontSize: "11px", fontStyle: "italic"}}>
                              "Como estudiante de informática, sentía que me faltaba experiencia práctica para destacar en el mercado laboral. Gracias al bootcamp, pude desarrollar proyectos reales y adquirir habilidades actuales que no enseñan en la universidad. Ahora, mientras termino mis estudios, ya trabajo como Full Stack Developer en una empresa de tecnología, ganando más de $4,000/mes. ¡El bootcamp fue el complemento perfecto para mi carrera!"
                        
                              <br/>
                              <b>
                              – Javier L., estudiante de informática y desarrollador en una empresa de tecnología.
                        
                              </b>
                              </p>
                            </div>
                          </div>
                        
                          <div className="d-flex my-3" style={{borderRadius: "15px", boxShadow: "5px 2px 9px 9px #dee2e6", padding: "5px", alignItems: "flex-start"}}>
                          <img className="ml-1" style={{borderRadius: "30px", marginRight: "5px"}} height="65px"
                            src="/assets/images/team/faces/face_3.webp" 
                            />
                            <div className="ml-2 mt-2">
                              <h5 style={{fontStyle: "italic"}} className="my-0">Bladimir G.
                                <FaFacebookSquare style={{marginLeft: "5px", fontSize: "20px", color: "#1877F2"}}/>
                                <span style={{marginLeft: "5px", fontSize: "13px", color: "gray"}}></span>
                              </h5>
                              <p className="my-0"><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/></p>
                              
                              <p style={{fontSize: "11px", fontStyle: "italic"}}>
                              "Decidí inscribirme en el bootcamp Full Stack Web y fue la mejor decisión de mi vida. Ahora tengo la oportunidad de trabajar con importantes empresas y mis ingresos han mejorado significativamente ¡Gracias Dojofullstack, por brindarme una educación de calidad!"
                              <br/>
                              <b>
                                 – Bladimir G., ex principiante y ahora Developer en una empresa internacional.
                              </b>
                              </p>
                            </div>
                          </div>
                        
                        
                          <div className="d-flex my-3" style={{borderRadius: "15px", boxShadow: "5px 2px 9px 9px #dee2e6", padding: "5px", alignItems: "flex-start"}}>
                          <img className="ml-1" style={{borderRadius: "30px", marginRight: "5px"}} height="65px"
                            src="/assets/images/team/faces/face_4.webp" 
                            />
                            <div className="ml-2 mt-2">
                              <h5 style={{fontStyle: "italic"}} className="my-0">Victor R.
                              <FaFacebookSquare style={{marginLeft: "5px", fontSize: "20px", color: "#1877F2"}}/>
                        
                                <span style={{marginLeft: "5px", fontSize: "13px", color: "gray"}}></span>
                              </h5>
                              <p className="my-0"><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/></p>
                        
                              <p style={{fontSize: "11px", fontStyle: "italic"}}>
                              "Cuando seguí esta ruta, pasé de vender ropa en línea a ganar $3,000/mes como Developer Full Stack."
                              <br/>
                              <b>
                                 – Victor R., ex principiante y ahora Developer en una empresa internacional.
                              </b>
                              </p>
                            </div>
                          </div>
                        
                        
                        
                        
                        
                          <div className="d-flex my-3" style={{borderRadius: "15px", boxShadow: "5px 2px 9px 9px #dee2e6", padding: "5px", alignItems: "flex-start"}}>
                          <img className="ml-1" style={{borderRadius: "30px", marginRight: "5px"}} height="65px"
                            src="/assets/images/team/faces/face_1.webp" 
                            />
                            <div className="ml-2 mt-2">
                              <h5 style={{fontStyle: "italic"}} className="my-0">Laura M.
                              <FaFacebookSquare style={{marginLeft: "5px", fontSize: "20px", color: "#1877F2"}}/>
                        
                                <span style={{marginLeft: "5px", fontSize: "13px", color: "gray"}}></span>
                              </h5>
                              <p className="my-0"><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/></p>
                        
                              <p style={{fontSize: "11px", fontStyle: "italic"}}>
                              "Soy mamá de dos niños y trabajaba como profesora de primaria.
                        A los 30 años, pensé que era tarde para cambiar de carrera…
                        ¡Hoy trabajo como Full Stack Developer para una empresa alemana
                        y gano $3,800/mes desde casa! Lo mejor: veo crecer a mis hijos
                        mientras diseño aplicaciones educativas que usan miles de alumnos."
                        
                              <br/>
                              <b>
                                 – Laura M., egresada del programa en 12 meses.
                        
                              </b>
                              </p>
                            </div>
                          </div>
                        
                        
                        
                          <div className="d-flex my-3" style={{borderRadius: "15px", boxShadow: "5px 2px 9px 9px #dee2e6", padding: "5px", alignItems: "flex-start"}}>
                          <img className="ml-1" style={{borderRadius: "30px", marginRight: "5px"}} height="65px"
                            src="/assets/images/dojopy/01920912.png" 
                            />
                            <div className="ml-2 mt-2">
                              <h5 style={{fontStyle: "italic"}} className="my-0">Maria Sanchez
                              <FaFacebookSquare style={{marginLeft: "5px", fontSize: "20px", color: "#1877F2"}}/>
                        
                                <span style={{marginLeft: "5px", fontSize: "13px", color: "gray"}}></span>
                              </h5>
                              <p className="my-0"><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/></p>
                        
                              <p style={{fontSize: "11px", fontStyle: "italic"}}>
                              Siempre pensé que una carrera en tecnología estaba fuera de mi alcance. Sin embargo, el bootcamp no solo me enseñó habilidades técnicas, sino también cómo aplicarlas en el mundo real. Ahora trabajo para una empresa de tecnología educativa y gano más de $2,300/mes. Lo mejor es que puedo trabajar remotamente y pasar tiempo de calidad con mi hijo.
                        
                              <br/>
                              <b>
                                 – Maria Sanchez., egresada del programa en 12 meses.
                        
                              </b>
                              </p>
                            </div>
                          </div>
                          </div>
              


                    </div>


                    
                </div>
    </>
    }




               <Modal show={this.state.isOpenPlanPay} size="md" onHide={() => this.setState({isOpenPlanPay: false})} style={{zIndex: "9999999999"}} >
                    <span style={{textAlign: "left", "color":"slateblue"}}> <IoCloseCircleOutline onClick={() => this.setState({isOpenPlanPay: false}) } style={{ cursor: "pointer", fontSize: "35px", marginLeft: "12px", marginTop: "12px", color: "slateblue"}} /> </span>
                    <Modal.Body>
                    <div className="">
                        <div className="section-title">
                            <h3 className="title"></h3>

                            <div className="pricing-body mb-3">

                                     <img style={{borderRadius: "20px"}} src={this.state.countryCode === 'PE' ? this.state.planPagoPE :this.state.planPagoUSD } height={'600px'} />
                                    
                                      </div>
                                      <div className="mb-1 text-center">
                                        <button style={{fontSize: "18px"}} className="rn-button-style--2 btn-solid" onClick={() => this.setState({isOpenPlanPay: false})}>{true ? "Cerrar": "Close"}</button>
                                      </div>
                    </div>

                    </div>

                    </Modal.Body>
                </Modal>





                <Modal show={this.state.isOpenResume} size="md" onHide={() => this.setState({isOpenResume: false})} style={{zIndex: "9999999999"}} >
                    <span style={{textAlign: "left", "color":"slateblue"}}> <IoCloseCircleOutline onClick={() => this.setState({isOpenResume: false}) } style={{ cursor: "pointer", fontSize: "35px", marginLeft: "12px", marginTop: "12px", color: "slateblue"}} /> </span>

                    <Modal.Body>
                    <div className="">
                        <div className="section-title">
                            <h3 className="title"></h3>

                            <div className="pricing-body mb-3">

                                      <ul className="list-style--1" style={{marginTop: "5px", color: 'black'}}>

                                        <div className="row">
                                            <div className="col-1">
                                                <span style={{"color":"slateblue", "fontSize": "22px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "4px", color: "slateblue"}} /> </span>
                                            </div>
                                            <div className="col-10 pl-2">
                                            <p style={{fontSize: "16px", "margin-bottom": "5px", fontWeight: "bold"}}>
                                                <b style={{color: "#1f1f25"}}> Te ayudamos a despegar y potenciar tus habilidades, esto puede ahorrarle años valiosos de su tiempo.</b> <br/>
                                            </p>
                                            </div>
                                            </div>

                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Aprende 100% online en vivo. <br/>
                                          </p>

                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          📅 Duración: 4 meses <br/>
                                          </p>

                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Grupo reducido
                                          <br/></p>

                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          5 horas en Vivo y 2 horas de actividades asíncronas, cada semana. <br/>
                                          </p>

                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                           Seguimiento y Tutorias 1 a 1. <br/>
                                          </p>


                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Prácticas y Código colaborativo en tiempo real en cada sesión.<br/></p>


                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Aprende con proyectos Full Stack reales <br/> (Tienda Online, Web de pedidos)
                                          </p>


                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Las sesiones en vivo quedan grabadas, acceso a la plataforma para siempre, para seguir practicando y aprendiendo. <br/></p>

                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Certificación<br/></p>

                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Una comunidad de apoyo profesional de por vida.<br/></p>


                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Portafolio de proyectos y carta de presentación optimizada 👌.
                                          <br/></p>

                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Programa de empleo, acceso a la bolsa laboral con empresas aliadas a nivel local e internacional. <br/></p>

                                      </ul>
                                      </div>
                                      <div className="mb-1 text-center">
                                        <button style={{fontSize: "18px"}} className="rn-button-style--2 btn-solid" onClick={() => this.setState({isOpenResume: false})}>{true ? "Cerrar": "Close"}</button>
                                      </div>
                    </div>


                    </div>


                    </Modal.Body>
               
                </Modal>



                <Modal show={this.state.isBeca50} size="md" onHide={() => this.setState({isBeca50: false})} style={{zIndex: "9999999999"}} >
                <span style={{textAlign: "left", "color":"slateblue"}}> <IoCloseCircleOutline onClick={() => this.setState({isBeca50: false})} style={{ cursor: "pointer", fontSize: "35px", marginLeft: "12px", marginTop: "12px", color: "slateblue"}} /> </span>

                <p style={{textAlign: 'center', fontSize: '23px', color: 'white', marginBottom: '5px'}}>Finaliza en</p>
                <FlipCountdown
                            size={'small'}
                            yearTitle='Year'
                            dayTitle='Días'
                            hourTitle='Horas'
                            minuteTitle='Min'
                            secondTitle='Segs'
                            monthTitle='Mes'
                            hideYear
                            hideMonth
                            endAt={this.state.fechaLimite} // Date/Time
                        />

                    <Modal.Body>

                    <div className="mb-3">
                        <p style={{textAlign: 'center', fontSize: '23px', color: 'white', marginTop: '5px'}}>¡Últimas becas disponibles!</p>
                    </div>

                    <div>
                        {this.renderBeca()}
                    </div>

                    </Modal.Body>
            
                </Modal>







                <Modal show={this.state.isOpen} size="lg" onHide={this.openModal} >
                    <Modal.Body>
                        <img alt="certificado Desarrollador Web Full Stack" src="/assets/images/dojopy/CERTIFICADO_fullstack_js.webp"></img>
                    </Modal.Body>
                    <Modal.Footer style={{"justify-content": "center"}}>
                    <button className="rn-btn" onClick={this.openModal}>{true ? "Cerrar": "Close"}</button>
                    </Modal.Footer>
                </Modal>


                <Modal show={this.state.isOpen2} size="md" onHide={this.openModal2} style={{zIndex: "9999999999"}} >
                <span style={{textAlign: "left", "color":"slateblue"}}> <IoCloseCircleOutline onClick={() => this.openModal2()} style={{ cursor: "pointer", fontSize: "35px", marginLeft: "12px", marginTop: "12px", color: "slateblue"}} /> </span>

                    <Modal.Body>
                    <div className="about-inner inner">
                        <div className="section-title">
                            <p>
No te preocupes. Nuestros programas son aptos para principiantes y no requieren ningún conocimiento previo ni hay limite de edad.
Además, no estarás solo. Nuestro equipo estará a tu lado para guiarte en el proceso, ayudarte a establecer objetivos y a tener éxito.
                            </p>
                            <p>
                                <b>Instalar Zoom:</b>
                                <img style={{borderRadius: "10px"}} alt="canal zoom" height="55px" src="/assets/images/dojopy/zoom_.webp"></img>
                            </p>
                    </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{"justify-content": "center"}}>
                    <button className="rn-btn" onClick={this.openModal2}>{true ? "Cerrar": "Close"}</button>
                    </Modal.Footer>
                </Modal>


                <Modal show={this.state.isOpen3} size="md" onHide={this.openModal3} style={{zIndex: "9999999999"}} >
                    <Modal.Body>
                    <div className="about-inner inner">
                        <div className="section-title">
                            <h3 className="title"></h3>
                            <p>
                                Las aplicaciones web y los productos digitales cambiaron la forma en la que nos conectamos,
                             comunicamos y vivimos. <br/>
                              Conocer las bases de la programación y adquirir una base sólida de herramientas para desenvolverse en el mundo del desarrollo web es clave. <br/>
                               Hoy, la tecnología atraviesa todos los trabajos y, saber programar, se convirtió en una habilidad clave. <br/>
                                Aprenderás desde los fundamentos de la programación, hasta el lanzamiento de aplicaciones web completas; a resolver problemas aplicando las mejores prácticas de la industria Tech.
                            </p>
                    </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{"justify-content": "center"}}>
                    <button className="rn-btn" onClick={this.openModal3}> {true ? "Cerrar": "Close"} </button>
                    </Modal.Footer>
                </Modal>


        <Modal show={this.state.isOpenTemario} size={window.type_device ? "sm": "md"} onHide={this.openModalEmail} style={{zIndex: "999999", alignSelf: "center"}} >
                <div style={{marginTop: "15px"}}>
                <span style={{fontSize: "20px", "text-align": "left", fontWeight: "600", marginBottom: "1px", color: "#1f1f25"}}>
                    <IoCloseCircleOutline onClick={() => this.openModalEmail()} style={{marginRight: "10px", cursor: "pointer", fontSize: "39px", marginLeft: "12px", color: "slateblue"}} />
                    Plan del Bootcamp
                    </span>
                </div>

            <Modal.Body className="modal-dojopy p-0">
                <div className="inner">
                    <div className="contact-form--1">
                        <div className="container" style={{maxWidth: "370px"}}>
                            <div className="row row--35 align-items-center justify-content-center">
                                <div>

                                <div style={{textAlign: "-webkit-center"}} className="form-wrapper">
                                <div className="thumbnail" style={{paddingBottom: "8px", maxWidth: window.type_device ? "310px": "370px" }}>
                                <img style={{boxShadow: "#424241 6px 5px 15px 0px"}} className="image_temario" src={true ? "/assets/images/dojopy/syllabus-compress.png": "/assets/images/dojopy/syllabus-compress.png"} alt="temario bootcamp"/>
                                </div>


                    <form onSubmit={this.onSubmit}>
                        <div className="row p-1">
                        <div className="col-12 text-center p-1" >

                        <label className="col-12 text-left font-weight-bold px-4 text-white" htmlFor="nameDojo">
                            Nombre:
                            <input
                            id='nameDojo'
                            style={{marginBottom: "5px", backgroundColor: 'white'}}
                            type="text"
                            name="name"
                            value={this.state.Name}
                            onChange={(e) => this.setState({ Name: e.target.value})}
                            placeholder= {"Hola! ¿Cuál es tu nombre?"}
                            />
                        </label>

                        {/* <label className="col-12 text-left font-weight-bold px-4 text-white" htmlFor="emailDojo">
                            Email:
                            <input
                            style={{marginBottom: "5px", backgroundColor: 'white'}}
                            type="email"
                            name="email"
                            id="emailDojo"
                            value={this.state.Email}
                            onChange={(e) => this.setState({ Email: e.target.value})}
                            placeholder= {true ? "¿Cuál es tu Email?": "What is your email?"}
                            />
                        </label> */}
             

                                <button className="rn-button-style--2 btn-solid" type="submit" name="submit" id="mc-embedded-subscribe-" style={{maxWidth: "95%", fontSize: "18px", marginTop: '1px', fontWeight: "bold"}}>
                                {!this.state.loadingEnroll  && <MdEmail style={{fontSize:"25px", marginRight: "5px"}} />}
                                {this.state.loadingEnroll  &&
                                                <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                style={{fontSize: "20px", marginRight: "5px"}}
                                                />}
                                {true ? "Descargar programa": "Send program"}
                                </button>

                                <div className="col-12 mt-2" style={{color: "white", fontSize: "13px", textAlign: "left"}}>
                    <label htmlFor="checkNotify" style={{float: "left", color: "#1f1f25"}}>
                        <input
                            style={{width: 16, float: "left", marginTop: "-10px", marginRight: "9px", marginBottom: "1px"}}
                            defaultChecked={true}
                            type="checkbox"
                            name="checkNotify"
                            id="checkNotify"
                        />
                        <span>{true ? "Protección de datos y privacidad.": "Data protection and privacy."} </span>
                    </label>
                    </div>

                        </div>
                        </div>

                        </form>

                   

                        </div>

                        </div>
                        </div>
                        </div>
                        </div>
                    </div>
                    </Modal.Body>
        </Modal>



        <Modal show={this.state.isModalWS} size={window.type_device ? "md": "md"} onHide={() => this.setState({isModalWS: false}) } style={{zIndex: "9999999999"}} aria-hidden="true"  >

                <div style={{marginTop: "15px", position: 'relative'}}>
                <span style={{ zIndex: "99999999999", position: "absolute", right: "0", fontSize: "20px", "text-align": "left", fontWeight: "600", marginBottom: "1px", color: "#1f1f25"}}>
                    <IoCloseCircleOutline onClick={() => this.setState({isModalWS: false}) } style={{marginRight: "10px", cursor: "pointer", fontSize: "39px", marginLeft: "12px", color: "slateblue"}} />
                    </span>
                </div>

            <Modal.Body aria-hidden="true">
                <div className="about-inner inner">
                    <div className="section-title">
                        <h3 className="p-2 text-dark" style={{lineHeight: window.type_device ? "30px": "40px", marginTop:  window.type_device ? "5px": "35px" }}>
                        ¡Obtén el Plan Completo del Bootcamp Full Stack! 🚀
                         </h3>
                    <p className="p-1 text-dark my-0" style={{fontSize: "16px"}}>
                        Completa el formulario a continuación para recibir toda la información sobre nuestro programa.
                    </p>

                 

                    <div className=""  id="formulario" data-aos="fade-right">
                            <div className="contact-form--1">
                                <div className="container" style={{maxWidth: "570px"}}>
                            <div className="align-items-center justify-content-center">
                                <div>
                                <div style={{textAlign: "-webkit-center"}} className="form-wrapper">



                    <form onSubmit={this.onSubmit}>
                        <div className="row p-1">
                        <div className="col-12 text-center p-1" >

                        <label className="col-12 text-left font-weight-bold px-1 text-black" htmlFor="nameDojo">
                        <span style={{fontSize: "18px", display: "inline-block", marginBottom: "5px"}}>
                        👋 ¡Hola! ¿Cuál es tu nombre?
                        </span>
                            <input
                            id='nameDojo'
                            style={{marginBottom: "5px", backgroundColor: 'white', fontSize: "18px"}}
                            type="text"
                            name="name"
                            value={this.state.Name}
                            onChange={(e) => this.setState({ Name: e.target.value})}
                            placeholder= {"Escribe tu nombre aquí"}
                            />
                        </label>

                        <label className="col-12 text-left font-weight-bold px-1 text-black mt-3" htmlFor="emailDojo">
                            <span style={{fontSize: "18px", display: "inline-block", marginBottom: "5px"}}>
                                
                            </span>
                            <input
                            style={{marginBottom: "5px", backgroundColor: 'white', fontSize: "18px"}}
                            type="email"
                            name="email"
                            id="emailDojo"
                            value={this.state.Email}
                            onChange={(e) => this.setState({ Email: e.target.value})}
                            placeholder="tuemail@example.com"
                            />
                        </label>

                                <button className="btn-efect-live mt-2" type="submit" name="submit" id="mc-embedded-subscribe-"
                                    style={{color: "white", fontFamily: "Poppins,sans-serif", width: "100%", fontSize: "25px", fontWeight: "bold", textTransform: "uppercase"}}>
                                {this.state.loadingEnroll  &&
                                                <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                style={{fontSize: "20px", marginRight: "5px"}}
                                                />}
                                Obtener Plan Completo
                                </button>

                                <div className="col-12 mt-2 text-center" style={{color: "white", fontSize: "13px", textAlign: "center"}}>
                    <label htmlFor="checkNotify" style={{float: "left", color: "#1f1f25"}}>
                        <input
                            style={{width: 16, float: "left", marginTop: "-10px", marginRight: "9px", marginBottom: "1px"}}
                            defaultChecked={true}
                            type="checkbox"
                            name="checkNotify"
                            id="checkNotify"
                        />
                        <span className="">{true ? "Protección de datos y privacidad.": "Data protection and privacy."} </span>
                    </label>
                    </div>

                        </div>
                        </div>

                        </form>


                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                    </div>

      
                
            </div>
            </div>
            </Modal.Body>
        </Modal>





        <Modal 
        backdrop="static"   // Esto evita que el modal se cierre al hacer clic fuera
        keyboard={false}
          id="modal-info" show={this.state.isOpenModalInfo} size={window.type_device ? "sm": "md"} onHide={() => this.setState({isOpenModalInfo: false}) } style={{zIndex: "999999", alignSelf: "center"}} >
                <div style={{marginTop: "20px", marginBottom: '10px'}}>
                    <p className="mt-3" style={{fontFamily: "Poppins, sans-serif", fontWeight: 'bold', fontSize: "30px", "text-align": "center", marginBottom: "1px", color: "white"}}>
                        ¡Próximamente, <br/> disponible en tu país!
                    </p>
                </div>

            <Modal.Body className="modal-dojopy p-0" >
                <div className="inner">
                    <div className="contact-form--1">
                        <div className="container" style={{maxWidth: "370px"}}>
                            <div className="row row--35 align-items-center justify-content-center">
                                <div>

                                <div style={{textAlign: "-webkit-center"}} className="form-wrapper">
                              

                   

                        </div>

                        </div>
                        </div>
                        </div>
                        </div>
                    </div>
                    </Modal.Body>
        </Modal>




        <Modal show={this.state.isOpenReunion} size={window.type_device ? "sm": "md"} onHide={() => this.setState({isOpenReunion: false})} style={{zIndex: "999999", alignSelf: "center" }} >
                <div style={{marginTop: "15px", position: 'relative'}}>
                <span style={{position: "absolute", right: "0", fontSize: "20px", "text-align": "left", fontWeight: "600", marginBottom: "1px", color: "#1f1f25"}}>
                    <IoCloseCircleOutline onClick={() => this.setState({isOpenReunion: false}) } style={{marginRight: "10px", cursor: "pointer", fontSize: "39px", marginLeft: "12px", color: "slateblue"}} />
                    </span>
                    <img src="/assets/images/dojopy/50-off.png" style={{height: '90px', position: 'absolute', right: 0, margin: '5px'}} />
                </div>

            <Modal.Body className="modal-dojopy p-0" >
                <div className="inner">
                    <div className="contact-form--1">
                        <div className="container" style={{maxWidth: "370px"}}>

                            <div className="row row--35 align-items-center justify-content-center">
                                <div>

                                <div style={{textAlign: "-webkit-center"}} className="form-wrapper">
                            
                            <h3 className="mb-1 mt-5 text-white">
                            Agenda una reunión informativa y obtén una beca del 50%
                            </h3>

                    <form onSubmit={this.onSubmit}>
                        <div className="row p-1">
                        <div className="col-12 text-center p-1" >

                        <label className="col-12 text-left font-weight-bold px-4" htmlFor="nameDojo">
                            
                            <p style={{color: "white", marginBottom: '3px', marginTop: '1px', fontSize: '15px', textAlign: 'left', fontWeight: 'bold'}}>Nombre:</p>
                            <input
                            id='nameDojo'
                            style={{marginBottom: "5px", backgroundColor: 'white'}}
                            type="text"
                            name="name"
                            value={this.state.Name}
                            onChange={(e) => this.setState({ Name: e.target.value})}
                            placeholder= {"Hola! ¿Cuál es tu nombre?"}
                            />
                        </label>

                        {/* <label className="col-12 text-left font-weight-bold px-4" htmlFor="emailDojo">
                           
                        <p style={{color: "white", marginBottom: '3px', marginTop: '1px', fontSize: '15px', textAlign: 'left', fontWeight: 'bold'}}>Email:</p>

                            <input
                            style={{marginBottom: "5px", backgroundColor: 'white'}}
                            type="email"
                            name="email"
                            id="emailDojo"
                            value={this.state.Email}
                            onChange={(e) => this.setState({ Email: e.target.value})}
                            placeholder= {true ? "¿Cuál es tu Email?": "What is your email?"}
                            />
                        </label> */}


    <p style={{color: "white", marginBottom: '3px', marginTop: '1px', fontSize: '15px', marginLeft: '1.5rem', textAlign: 'left', fontWeight: 'bold'}}>
    N.º de WhatsApp:
    </p>

<div className="d-flex justify-content-center mx-4">
<div className="mx-1 mt-2">
    <Selectrix
    className="pais-inscription"
    height={300}
    onRenderOption={this.onRenderOption}
    onRenderSelection={this.onRenderSelection}
    placeHolderInside={true}
    placeholder={"País"}
    customScrollbar={true}
    searchable={false}
    materialize={false}
    defaultValue={this.state.countryCode}
    options={KeyCountry}
    onChange={(value) => {
        if (this.state.Email){
            localStorage.setItem('email', this.state.Email);
        }
        if (this.state.Name){
            localStorage.setItem('name', this.state.Name);
        }
        if (this.state.Phone){
            localStorage.setItem('phone', this.state.Phone);
        }
    } }
    />

</div>

<div>
<label htmlFor="Phone">
    
    <input
    style={{background: "white"}}
    type="text"
    name="phone"
    id="Phone"
    value={this.state.Phone}
    onChange={(e) => {
        this.setState({ Phone: e.target.value });
    }}
    placeholder="N.º Celular"
    />
 </label>
</div>

</div>

             

                                <button className="btn-efect" type="submit" name="submit" id="mc-embedded-subscribe-" style={{maxWidth: "95%", fontSize: "23px", marginTop: '1px', fontWeight: "bold"}}>
                                {this.state.loadingEnroll  &&
                                                <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                style={{fontSize: "20px", marginRight: "5px"}}
                                                />}
                                Agendar reunión
                                </button>

                                <div className="col-12 mt-2" style={{color: "white", fontSize: "13px", textAlign: "left"}}>
                    <label htmlFor="checkNotify" style={{float: "left", color: "#1f1f25"}}>
                        <input
                            style={{width: 16, float: "left", marginTop: "-10px", marginRight: "9px", marginBottom: "1px"}}
                            defaultChecked={true}
                            type="checkbox"
                            name="checkNotify"
                            id="checkNotify"
                        />
                        <span>{true ? "Protección de datos y privacidad.": "Data protection and privacy."} </span>
                    </label>
                    </div>

                        </div>
                        </div>

                        </form>

                   

                        </div>

                        </div>
                        </div>
                        </div>
                        </div>
                    </div>
                    </Modal.Body>
        </Modal>



        {false &&
        <Modal show={this.state.isOpenSemiBeca} size="md" onHide={() => this.setState({isOpenSemiBeca: false})} style={{zIndex: "999999", alignSelf: "center"}}>
                <div style={{marginTop: "15px"}}>
                <span style={{fontSize: "20px", "text-align": "left", fontWeight: "600", marginBottom: "1px", color: "#1f1f25"}}>
                    <IoCloseCircleOutline onClick={() => this.setState({isOpenSemiBeca: false})} style={{marginRight: "10px", cursor: "pointer", fontSize: "39px", marginLeft: "12px", color: "slateblue"}} />
                    </span>

                    <span style={{alignSelf: "center", marginRight: "15px", marginTop: "15px", fontWeight: "bold", fontSize: "16px" }}>
                            Finaliza en:
                        </span>

                    <span style={{display: "inline-flex", marginLeft: "10px"}}>
                      
                    <FlipCountdown
                            size='small'
                            yearTitle='Year'
                            dayTitle='Días'
                            hourTitle='Horas'
                            minuteTitle='Min'
                            secondTitle='Segs'
                            monthTitle='Mes'
                            hideYear
                            hideMonth
                            hideDay
                            endAt={this.state.timestamp24h} // Date/Time
                            />
                    </span>


                </div>

            <Modal.Body >
                <div className="inner">
                    <div className="contact-form--1">
                        <div className="container" style={{maxWidth: "370px"}}>
                            <div className="row row--35 align-items-center">
                                <div>

                                <div style={{textAlign: "-webkit-center"}} className="form-wrapper">
                                <div className="thumbnail" style={{paddingBottom: "8px" }}>
                                <img style={{boxShadow: "#424241 6px 5px 15px 0px"}} className="image_temario" src={this.state.semibeca_img} alt="Descuento Especial"/>
                                </div>


                    <form onSubmit={this.onSubmitBeca}>
                        <div className="row p-1">
                        <div className="col-12 text-center p-1" >

                        {/* <label className="col-12" htmlFor="item02">
                            <input
                            style={{marginBottom: "5px"}}
                            type="email"
                            name="email"
                            id="item02"
                            value={this.state.Email}
                            onChange={(e) => this.setState({ Email: e.target.value})}
                            placeholder="¿Cuál es tu correo electrónico?"
                            />
                        </label> */}


                    <div className="row p-2">
                        <div className="col-5" style={{maxWidth: "145px"}}>
                            <Selectrix
                            height={300}
                            onRenderOption={this.onRenderOption}
                            onRenderSelection={this.onRenderSelection}
                            placeHolderInside={true}
                            placeholder={"País"}
                            customScrollbar={true}
                            searchable={false}
                            materialize={false}
                            defaultValue={this.state.countryCode}
                            options={KeyCountry}
                            onChange={(value) => {
                                // if (value.key === 'US'){ window.location.href = '/us'}
                                this.DynamicCountry(value.key)}
                            }
                            />
                        </div>


                        <label className="col-7" htmlFor="Phone" style={{paddingLeft: "1px"}}>
                            <input
                            style={{marginBottom: "5px"}}
                            type="text"
                            name="phone"
                            id="Phone"
                            value={this.state.Phone}
                            onChange={(e) => {
                                this.setState({ Phone: e.target.value });
                            }}
                            placeholder="N.º WhatsApp"
                            />
                        </label>

                    </div>

                    <div className="col-12" style={{color: "white", fontSize: "12.5px", textAlign: "left"}}>
                    <label htmlFor="checkNotify" style={{float: "left", color: "#1f1f25"}}>
                        <input
                            style={{width: 18, float: "left", marginTop: "-10px", marginRight: "9px", marginBottom: "1px"}}
                            defaultChecked={true}
                            type="checkbox"
                            name="checkNotify"
                            id="checkNotify"
                        />
                        <span style={{fontSize: "12.5px"}}>Protección de datos y privacidad.
                        </span>
                    </label>
                    </div>

                                <button className="rn-button-style--2 btn-solid" type="submit" name="submit" id="mc-embedded-subscribe-" style={{maxWidth: "95%", fontSize: "18px", marginTop: '1px', fontWeight: "bold"}}>
                                <GoRocket style={{fontSize:"25px"}} /> Obtener descuento adicional
                                </button>
                        </div>
                        </div>

                        </form>

                        <div>
                        <button onClick={() => this.setState({isOpenSemiBeca: false}) } className="rn-button-style--1 btn-solid" type="submit" name="submit"  style={{marginTop: '13px', fontSize: "18px"}}>
                        {true ? "Cerrar": "Close"}
                        </button>
                        </div>

                        </div>

                        </div>
                        </div>
                        </div>
                        </div>
                    </div>
                    </Modal.Body>
                </Modal>
        }



                <Modal show={this.state.isOpenSuscribe} size="xl" onHide={() => this.setState({isOpenSuscribe: false, selectFecha: false})} style={{top:  window.type_device ? "1%": "15%", zIndex: "999999"}}>

                <span style={{marginLeft: "5px", marginTop: "5px", textAlign: "left", "color":"slateblue"}}> <IoCloseCircleOutline onClick={() => this.setState({isOpenSuscribe: false, selectFecha: false}) } style={{ cursor: "pointer", fontSize: "41px", marginLeft: "12px", marginRight: "12px", marginTop: "12px", color: "slateblue"}} /> </span>

                <Modal.Body className="body-evento">

                <div className="inner">
                    <div className="contact-form--1">
                            <div className="row">
                                <div className="col-12 col-md-7 px-2">
                                <h3 className="mb-1" style={{fontSize: window.type_device ? "33px": "43px"}}>
                                Bootcamp Full Stack
                                </h3>

                                <div style={{width: window.type_device ? "250px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>
                                    <>
                                    <h5 className="mb-2 mt-1" style={{fontWeight: "bold"}}>
                                    Al solicitar el brochure recibirás toda la información clave para comenzar tu camino como desarrollador Full Stack:
                                    </h5>

                                    {!window.type_device && this.renderInfoBootcamp()}


                                    {/* formulario */}
                                    {window.type_device &&
                                    <>
                                     <div data-aos="fade-right" className="col-12 col-md-6 px-1" style={{marginBottom: "25px"}}>
                <div style={{textAlign: "center", padding: "5px", boxShadow: "rgba(50, 27, 98, 0.47) 6px 5px 15px 0px", border: "2px solid rgba(140, 82, 255, 0.9)", borderRadius: "20px" }}>

            <form onSubmit={this.onSubmit} className="px-1" style={{paddingTop: "10px", paddingBottom: "10px" }}>


        <label className="col-12 text-left font-weight-bold" htmlFor="nameDojo">
            <span style={{fontSize: "16px", color: "black"}}>
            Nombre:
            </span>
            <input
            id='nameDojo'
            style={{background: "white", marginBottom: "7px"}}
            type="text"
            name="name"
            value={this.state.Name}
            onChange={(e) => this.setState({ Name: e.target.value})}
            placeholder= {"👋 Hola! ¿Cuál es tu nombre?"}
            />
        </label>

           <label className="col-12 text-left font-weight-bold" htmlFor="item02">
            <span style={{fontSize: "16px", color: "black"}}>
            Email:
            </span>
                <input
                    style={{background: "white", marginBottom: "3px"}}
                    type="email"
                    name="email"
                    id="item02"
                    value={this.state.Email}
                    onChange={(e)=>{this.setState({Email: e.target.value});}}
                    placeholder="📧 ¿Cuál es tu Email?"
                    />
            </label> 


<div className="text-left mt-1" >
    <b style={{fontSize: "16px", marginLeft: "16px", color: "black"}}>
        N.º de WhatsApp:
    </b>
</div>

<div className="d-flex justify-content-center mx-2">

<div className="mx-2 mt-2">
    <Selectrix
    className="pais-inscription"
    height={300}
    onRenderOption={this.onRenderOption}
    onRenderSelection={this.onRenderSelection}
    placeHolderInside={true}
    placeholder={"País"}
    customScrollbar={true}
    searchable={false}
    materialize={false}
    defaultValue={this.state.countryCode}
    options={KeyCountry}
    onChange={(value) => {
        if (this.state.Email){
            localStorage.setItem('email', this.state.Email);
        }
        if (this.state.Name){
            localStorage.setItem('name', this.state.Name);
        }
        if (this.state.Phone){
            localStorage.setItem('phone', this.state.Phone);
        }
    } }
    />

</div>

<div style={{width: "80%"}}>
<label htmlFor="Phone">
    
    <input
    style={{background: "white"}}
    type="tel"
    name="phone"
    id="Phone"
    value={this.state.Phone}
    onChange={(e) => {
        this.setState({ Phone: e.target.value });
    }}
    placeholder="987654321"
    />
 </label>
</div>

</div>



<button
    id="submit-contacto"
    className="btn-efect"
    type="submit" name="submit-contacto"  style={{padding: "10px 20px", fontFamily: "'Poppins',sans-serif", width: "100%", fontSize: "25px", display: "block", margin: "auto", textTransform: "capitalize"}}>

{!this.state.loadingEnroll  && <b style={{fontSize: "27px"}}>Solicitar Brochure
    </b> }

{this.state.loadingEnroll  &&
                <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{fontSize: "20px", marginRight: "8px"}}
                />
}

</button>

   
   
<div className="row mt-3">
                    <div className="col-12 mx-3" style={{color: "gray", fontSize: "14px", textAlign: "left"}}>
                    <label htmlFor="checkNotify" style={{float: "left"}}>
                        <input
                            style={{width: 15, float: "left", marginTop: "-10px", marginRight: "5px", marginBottom: "1px"}}
                            defaultChecked={true}
                            type="checkbox"
                            name="checkNotify"
                            id="checkNotify"
                        />
                        <span>Protección de datos y privacidad.</span>
                    </label>
                    </div>
                </div>


            </form>
                    </div>
            

        </div>
                                    </>
                                    }

                           {window.type_device && this.renderInfoBootcamp()}

                                 
                                    </>
                                </div>

                                <div className="col-12 col-md-5" style={{paddingTop: "20px"}}>
                                {/* formulario */}
                                {!window.type_device &&
                                    <>
                                     <div data-aos="fade-right" className="col-12 px-1" style={{marginBottom: "25px"}}>
                                     <div style={{textAlign: "center", padding: "5px", boxShadow: "rgba(50, 27, 98, 0.47) 6px 5px 15px 0px", border: "2px solid rgba(140, 82, 255, 0.9)", borderRadius: "20px" }}>

            <form onSubmit={this.onSubmit} className="px-1" style={{paddingTop: "10px", paddingBottom: "10px" }}>

            <label className="col-12 text-left font-weight-bold" htmlFor="nameDojo">
            <span style={{fontSize: "16px", color: "black"}}>
            Nombre:
            </span>
            <input
            id='nameDojo'
            style={{background: "white", marginBottom: "7px"}}
            type="text"
            name="name"
            value={this.state.Name}
            onChange={(e) => this.setState({ Name: e.target.value})}
            placeholder= {"👋 Hola! ¿Cuál es tu nombre?"}
            />
        </label>

           <label className="col-12 text-left font-weight-bold" htmlFor="item02">
            <span style={{fontSize: "16px", color: "black"}}>
            Email:
            </span>
                <input
                    style={{background: "white", marginBottom: "3px"}}
                    type="email"
                    name="email"
                    id="item02"
                    value={this.state.Email}
                    onChange={(e)=>{this.setState({Email: e.target.value});}}
                    placeholder="📧 ¿Cuál es tu Email?"
                    />
            </label> 


<div className="text-left mt-1" >
    <b style={{fontSize: "16px", marginLeft: "16px", color: "black"}}>
        N.º de WhatsApp:
    </b>
</div>

<div className="d-flex justify-content-center mx-2">

<div className="mx-2 mt-2">
    <Selectrix
    className="pais-inscription"
    height={300}
    onRenderOption={this.onRenderOption}
    onRenderSelection={this.onRenderSelection}
    placeHolderInside={true}
    placeholder={"País"}
    customScrollbar={true}
    searchable={false}
    materialize={false}
    defaultValue={this.state.countryCode}
    options={KeyCountry}
    onChange={(value) => {
        if (this.state.Email){
            localStorage.setItem('email', this.state.Email);
        }
        if (this.state.Name){
            localStorage.setItem('name', this.state.Name);
        }
        if (this.state.Phone){
            localStorage.setItem('phone', this.state.Phone);
        }
    } }
    />

</div>

<div style={{width: "80%"}}>
<label htmlFor="Phone">
    
    <input
    style={{background: "white"}}
    type="tel"
    name="phone"
    id="Phone"
    value={this.state.Phone}
    onChange={(e) => {
        this.setState({ Phone: e.target.value });
    }}
    placeholder="N.º de WhatsApp"
    />
 </label>
</div>

</div>



<button
    id="submit-contacto"
    className="btn-efect"
    type="submit" name="submit-contacto"  style={{padding: "10px 20px", fontFamily: "'Poppins',sans-serif", width: "100%", fontSize: "25px", display: "block", margin: "auto", textTransform: "capitalize"}}>

{!this.state.loadingEnroll  && <b style={{fontSize: "27px"}}>Solicitar Brochure
    </b> }

{this.state.loadingEnroll  &&
                <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{fontSize: "20px", marginRight: "8px"}}
                />
}

</button>

   
   
<div className="row mt-3">
                    <div className="col-12 mx-3" style={{color: "gray", fontSize: "14px", textAlign: "left"}}>
                    <label htmlFor="checkNotify" style={{float: "left"}}>
                        <input
                            style={{width: 15, float: "left", marginTop: "-10px", marginRight: "5px", marginBottom: "1px"}}
                            defaultChecked={true}
                            type="checkbox"
                            name="checkNotify"
                            id="checkNotify"
                        />
                        <span>Protección de datos y privacidad.</span>
                    </label>
                    </div>
                </div>


            </form>
                    </div>
            

        </div>
                                    </>
                                    }
                                </div>


                                </div>
                        </div>
                        </div>


                    </Modal.Body>

                </Modal>










{this.state.showWeb && 

                <div className="rn-brand-area bg_color--1 ptb--80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 px-0">


                        <div class="section-title mb--25 mb_sm--0 text-center">
                            <h3 class="title text-dark" style={{lineHeight: "normal"}}>
                            NUESTROS GRADUADOS TRABAJAN EN CONOCIDAS EMPRESAS INTERNACIONALES
                             </h3>

                             <div className="d-flex justify-content-center">
                              <img className="col-12 col-md-6 w-100 img-fluid px-0" src="/assets/images/dojopy/brands_2025.png" alt="" srcset="" />
                             </div>
                        </div>

                            </div>
                        </div>
                    </div>
                </div>
    }





{this.state.showWeb && 

<div className="rn-testimonial-area bg_color--1 ptb--80" style={{"padding": "10px"}}>
    <div className="container">
            <div className="rn-testimonial-area bg_color--1 ptb--80" style={{"padding-bottom": "30px"}}>
                    <div className="container">
                        <TestimonialTree langUS={this.state.langUS} />
                    </div>
                </div>
    </div>
</div>
    }





{this.state.showWeb && 

  <div className="rn-team-area ptb--80 bg_color--1" id="team" data-aos="fade-right" >
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                        <h2 className="title">{true ? "Nuestro Equipo": "Our team"} </h2>
                                        <p>{true ? "Nos apasiona la tecnológica y los desafíos de este mundo cambiante.": "We are passionate about technology and the challenges of this changing world."}  </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{justifyContent: "center"}}>
                                <TeamFull column="col-3 col-md-1" />
                            </div>
                        </div>
                </div>
    }




                {/* Start Team Area  */}
                {/* <div data-aos="fade-up" className="rn-team-area ptb--80 bg_color--1" id="modules">
                    <div className="container" style={{"maxWidth": "900px"}}>
                        <div className="row">
                            <div className="col-12">
                                <div className="text-center mb--30">
                                    <h3 className="title">
                                    {true ? "El Bootcamp está formado por los siguientes módulos":
                                     "The Bootcamp is made up of the following modules"}
                                        </h3>
                                </div>
                                <div>
                            <AccordionWrapper>
                                <AccordionItem  index={0} title={true ? "Fundamentos del Desarrollo Web": "Fundamentals of Web Development"} description={true ? InfoAcordeon.web: InfoAcordeonUS.web}></AccordionItem>
                                <AccordionItem  index={1} title={true ? "Git & Github": "Git & Github"} description={true ? InfoAcordeon.git: InfoAcordeonUS.git}></AccordionItem>
                                <AccordionItem index={2} title={true ? "JavaScript": "JavaScript"} description={true ? InfoAcordeon.js: InfoAcordeonUS.js}></AccordionItem>
                                <AccordionItem index={3} title={true ? "Frontend con React JS": "Frontend with ReactJS"} description={true ? InfoAcordeon.react: InfoAcordeonUS.react}></AccordionItem>
                                <AccordionItem index={4} title={true ? "Bases de Datos": "Databases"} description={true ? InfoAcordeon.database: InfoAcordeonUS.database}></AccordionItem>
                                <AccordionItem index={5} title={true ? "Backend con Python y Django": "Backend with Python and Django"} description={true ? InfoAcordeon.python: InfoAcordeonUS.python}></AccordionItem>
                                <AccordionItem index={6} title={true ? "API REST": "API REST"} description={true ? InfoAcordeon.api: InfoAcordeonUS.api}></AccordionItem>
                                <AccordionItem index={7} title={true ? "Testing & Seguridad": "Testing & Security"} description={true ? InfoAcordeon.apiSecure: InfoAcordeonUS.apiSecure}></AccordionItem>
                                <AccordionItem index={8} title={true ? "Servicios en la nube con Amazon Web Services": "Deployment in the AWS cloud"} description={true ? InfoAcordeon.deploy_aws: InfoAcordeonUS.deploy_aws}></AccordionItem>
                                <AccordionItem index={9} title={true ? "Proyecto Full Stack": "Full Stack Project"} description={true ? InfoAcordeon.project: InfoAcordeonUS.project}></AccordionItem>
                                <AccordionItem index={10} title={true ? "Empleabilidad": "Employability"} description={true ? InfoAcordeon.employer: InfoAcordeonUS.employer}></AccordionItem>
                            </AccordionWrapper>
                                </div>
                            </div>
                        </div>

                    </div>


                </div> */}
                {/* End Team Area  */}



                {this.state.showWeb && 

  <div data-aos="fade-up" className="rn-team-area ptb--20 bg_color--1" id="roadmap" style={{marginBottom: "-5px" }}>
                    <div className="container" style={{"maxWidth": "900px"}}>
                        <div className="row">
                            <div className="col-12">
                                <div className="text-center mb--30">
                                    <h2 className="title">RoadMap</h2>
                                    <h5>
                                    El Bootcamp está formado por los siguientes Programas
                                    </h5>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
}


{this.state.showWeb && 
<VerticalTimeline
    lineColor="slateblue"
>
<VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'darkgreen', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  darkgreen' }}
    iconStyle={{ background: 'darkgreen', color: 'white' }}
    icon={<GoRocket style={{fontSize: "25px"}}/>}
  >
    <h3 className="vertical-timeline-element-title"> {true ? "Plataforma con acceso de por vida" : "Platform Access"} </h3>
                    <span style={{lineHeight: "20px", display: "inline-block", marginLeft: "7px", padding: "3px", "padding-top": "4px","padding-bottom": "4px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                        <span className="pulsar"></span> En vivo
                    </span>
                   
    <p>
    <>
        Como miembro de DojoFullStack, tendrás acceso inmediato a la
    Plataforma innovadora para prepararse y practicar antes de las sesiones en vivo del Bootcamp.
    La plataforma ofrece lecciones y retroalimentación personalizadas
    según el progreso del estudiante.
    También hay acceso a múltiples módulos, proyectos y soluciones compartidas de la comunidad, con acceso de por vida.

    </>

    </p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'midnightblue', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  midnightblue' }}
    iconStyle={{ background: 'midnightblue', color: 'white' }}
    icon={<FaRunning />}
  >
    <h3 className="vertical-timeline-element-title"> {true ? "Fundamentos del Desarrollo Web" : "Fundamentals of Web Development"} </h3>
  
    <p>
    <>
        Te familiarizarás con los conceptos y herramientas fundamentales del desarrollo web y adquirirás los conocimientos esenciales para abordar el resto del bootcamp.
        Trabajando con HTML y CSS, implementando prácticas de versionado de código con GIT, desplegarás tu sitio a un servidor y aprenderás a interactuar con este servicio.
    </>
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    contentStyle={{ background: 'midnightblue', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  midnightblue' }}
    className="vertical-timeline-element--work"
    iconStyle={{ background: 'midnightblue', color: '#fff' }}
    icon={<FaRunning />}

    >
    <h3 className="vertical-timeline-element-title">Git & Github</h3>
 
    <p>
    Aprende las herramientas esenciales que necesitas para trabajar en proyectos profesionales,
    un sistema de control de versiones es indispensable, aprende Git el SCV más popular y más utilizado.
    </p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    contentStyle={{ background: 'midnightblue', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  midnightblue' }}
    className="vertical-timeline-element--work"
    iconStyle={{ background: 'midnightblue', color: '#fff' }}
    icon={<FaRunning />}
  >
    <h3 className="vertical-timeline-element-title">JavaScript</h3>
   
    <p>
    Aprenderemos JavaScript, explorando el paradigma de programación orientada a objetos y la gestión de la asincronía. También introduciremos el concepto de APIs y aprenderás a consultarlas.

    </p>
  </VerticalTimelineElement>



  <VerticalTimelineElement
    contentStyle={{ background: 'midnightblue', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  midnightblue' }}
    className="vertical-timeline-element--work"
    iconStyle={{ background: 'midnightblue', color: '#fff' }}
    icon={<FaRunning />}
  >
    <h3 className="vertical-timeline-element-title">{true ? "Frontend con React JS" : "Frontend with ReactJS"} </h3>
   
    <p>
    {true ? <>
        En este módulo, que es el tercer nivel del bootcamp, aprenderás a programar por componentes, mediante Javascript y React, y también conocerás las ventajas de la utilización del flujos de datos.  Comprenderás la utilización del virtual DOM mediante los desarrollos de React JS. Al finalizar, podrás crear tus propias aplicaciones SPA, y estarás en condiciones de hacer cualquier desarrollo con uno de los frameworks más populares y avanzados del momento.
    </> : <>
    In this module, which is the third level of the bootcamp, you will learn to program by components, using Javascript and React, and you will also learn the advantages of using data flows. You will understand the use of the virtual DOM through React JS developments. Upon completion, you will be able to create your own SPA applications, and you will be able to do any development with one of the most popular and advanced frameworks of the moment.
    </> }
    </p>
  </VerticalTimelineElement>



  <VerticalTimelineElement
    contentStyle={{ background: 'rebeccapurple', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  slateblue' }}
    className="vertical-timeline-element--education"
    iconStyle={{ background: 'rebeccapurple', color: '#fff' }}
    icon={<FaRunning />}
    
  >
    <h3 className="vertical-timeline-element-title">{true ? "Backend con JavaScript" : ""} </h3>
    <h4 className="vertical-timeline-element-subtitle">
    
    </h4>
    <p>
    El backend es una parte esencial de cualquier aplicación web, encargada de procesar y almacenar datos, así como de gestionar la lógica y la comunicación con el frontend.
    </p>
  </VerticalTimelineElement>




  <VerticalTimelineElement
    contentStyle={{ background: 'rebeccapurple', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  slateblue' }}
    className="vertical-timeline-element--education"
    iconStyle={{ background: 'rebeccapurple', color: '#fff' }}
    icon={<FaRunning />}
    
  >
    <h3 className="vertical-timeline-element-title">{true ? "API REST" : "API REST"}  </h3>
    <h4 className="vertical-timeline-element-subtitle">
   
    </h4>
    <p>
    En este módulo de API REST con NodeJS explorarás los conceptos fundamentales de las APIs REST, como los métodos HTTP, los recursos, los endpoints y la autenticación. Aprenderás cómo diseñar una API RESTful siguiendo las mejores prácticas, manteniendo la coherencia y facilitando la interacción con otras aplicaciones y servicios.
    </p>
  </VerticalTimelineElement>







  <VerticalTimelineElement
    contentStyle={{ background: 'rebeccapurple', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  purple' }}
    className="vertical-timeline-element--education"
    iconStyle={{ background: 'rebeccapurple', color: '#fff' }}
    icon={<FaRunning />}
    
  >
    <h3 className="vertical-timeline-element-title"> {true ? "Bases de Datos" : "Databases"} </h3>
    <h4 className="vertical-timeline-element-subtitle">
    
    </h4>
    <p>
    Es muy importante saber qué, cómo y dónde se guardarán tus datos. Aprende desde cero los fundamentos, el lenguaje de consultas SQL y prácticas para administrar DBs profesionalmente.
    </p>
  </VerticalTimelineElement>




  <VerticalTimelineElement
    contentStyle={{ background: 'rebeccapurple', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  purple' }}
    className="vertical-timeline-element--education"
    iconStyle={{ background: 'rebeccapurple', color: '#fff' }}
    icon={<FaRunning />}
    
  >
    <h3 className="vertical-timeline-element-title">{true ? "Testing & Seguridad" : "Testing & Security"}  </h3>
    <h4 className="vertical-timeline-element-subtitle">
    
    </h4>
    <p>
    En este módulo aprenderás a implementar pruebas automatizadas para verificar el correcto funcionamiento de tu código, así como a identificar y solucionar posibles vulnerabilidades y riesgos de seguridad en tu aplicación. Este módulo te dotará de las habilidades necesarias para garantizar la confiabilidad y protección de tus proyectos web, ofreciendo una experiencia segura y libre de errores para los usuarios finales.
    </p>
  </VerticalTimelineElement>


  <VerticalTimelineElement
    contentStyle={{ background: 'rebeccapurple', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  slateblue' }}
    className="vertical-timeline-element--education"
    iconStyle={{ background: 'rebeccapurple', color: '#fff' }}
    icon={<FaRunning />}
    
  >
    <h3 className="vertical-timeline-element-title">{true ? "Backend con Python" : ""}  </h3>
    <h4 className="vertical-timeline-element-subtitle">
    
    </h4>
    <p>
    ¡Bienvenido al apasionante mundo del desarrollo backend con Python y Django! En este programa optativo, te sumergirás en la elegancia y potencia de Python y la eficiencia de Django para crear aplicaciones web robustas y escalables. Desde la creación de modelos y vistas hasta la implementación de autenticación y seguridad. 🌟
    </p>
  </VerticalTimelineElement>


  {/* <VerticalTimelineElement
    contentStyle={{ background: 'rebeccapurple', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  slateblue' }}
    className="vertical-timeline-element--education"
    iconStyle={{ background: 'rebeccapurple', color: '#fff' }}
    icon={<FaRunning />}
    
  >
    <h3 className="vertical-timeline-element-title">
    Desarrollo de Apps Móviles
  </h3>

  <p>
  🚀 ¡Crea apps increíbles con React Native! Aprende a desarrollar aplicaciones móviles multiplataforma para iOS y Android con una sola base de código.
  </p>
  
    <p>

    </p>
  </VerticalTimelineElement> */}


  <VerticalTimelineElement
    contentStyle={{ background: 'mediumseagreen', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  mediumseagreen' }}
    className="vertical-timeline-element--education"
    iconStyle={{ background: 'mediumseagreen', color: '#fff' }}
    icon={<FaRunning />}
  >
    <h3 className="vertical-timeline-element-title">{true ? "Proyectos Web Full Stack" : "Full Stack Web Project"} </h3>
   
    <p>
        El proyecto final sirve para certificar todo el aprendizaje poniéndolo en práctica creando una aplicación funcional inspirada en Rappi o Airbnb.
         El proyecto final es una herramienta increíble para agregar a tu portafolio y así mostrar tu trabajo en tus próximas oportunidades laborales 😎!
       </p>
  </VerticalTimelineElement>



  <VerticalTimelineElement
    contentStyle={{ background: 'darkorange', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  darkorange' }}
    className="vertical-timeline-element--education"
    iconStyle={{ background: 'darkorange', color: '#fff' }}
    icon={<FaRunning />}
  >
    <h3 className="vertical-timeline-element-title">{true ? "Servicios en la nube con Amazon Web Services" : "Deployment in the AWS cloud"}  </h3>
   
    <p>
    Implementa una Aplicación Web completa en la infraestructura de AWS y SERVERLESS.
     Aplicado para aplicaciones web y aplicaciones móviles.
    </p>
  </VerticalTimelineElement>






  <VerticalTimelineElement
    contentStyle={{ background: 'midnightblue', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  midnightblue' }}
    iconStyle={{ background: 'midnightblue', color: '#fff' }}
    icon={<MdWork />}
  >
    <h3 className="vertical-timeline-element-title">{true ? "Empleabilidad" : "Employability"} </h3>
    <p>
    Estamos tan seguros de la calidad de nuestra formación que ofrecemos una garantía de empleabilidad. Creemos en el poder de nuestra educación y en tu potencial.
    </p>

  </VerticalTimelineElement>

  <VerticalTimelineElement
    contentStyle={{ background: 'goldenrod', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  goldenrod' }}
    iconStyle={{ background: 'goldenrod', color: '#fff' }}
    icon={<FaGraduationCap />}
  >
    <h3 className="vertical-timeline-element-title">{true ? "Graduación BootCamp 2025" : "BootCamp Graduation 2023"}  </h3>
    <p>
        <b>
        🚀 Soy Full Stack Developer Certificado.
        </b>
    </p>

  </VerticalTimelineElement>



    </VerticalTimeline>
    }




{this.state.showWeb && 
    <PreguntasEmpleo langUS={this.state.langUS} bootcampSlug={this.state.bootcampSlug} />
    }


    
{false & this.state.showWebTestimonios && 

  <div className="about-area ptb--70 bg_color--5">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                            
                            <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Judith</h3>
                                        <h3 className="title" style={{color: "orange"}}>⭐ Consiguió Empleo</h3>
                                            <div>
                                            <iframe width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/alN6nU5a2Vo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Bladimir</h3>
                                        <h3 className="title" style={{color: "orange"}}>⭐ Consiguió Empleo</h3>
                                            <div>
                                            <iframe width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/dDPWdY-S8-0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Tatiana</h3>
                                            <div>
                                            <iframe width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/NhOFeo9_rA4?si=_LWhcwSgAOlmF0Od" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Harold Ivan</h3>
                                            <div>
                                            <iframe width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/CJWjmlO3_7k?si=0VWDgUmLhp4pyNRg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Stephany</h3>
                                            <div>
                                            <iframe width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/330Wxw_z4_0?si=Ms_tnorjQRspKGrw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Ricardo Osorio</h3>
                                            <div>
                                            <iframe width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/ZaR2-71Kg9Q?si=pz_7oW3sL6gOP2oG" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Diana</h3>
                                            <div>
                                            <iframe width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/gpLkbRA4ZrE?si=UV0v9lnJQn2IdwAA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                            <h3 className="title">Testimonio Paul</h3>
                                            <div>
                                            <iframe width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/81xtrnKV9Ss" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>


{/* 
                                <div className="col-md-6 col-12 mt-4 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title pt-4">Testimonio Oscar</h3>
                                            <div>
                                            <iframe width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/qtu4qNnBZbI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-12 mt-4 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title pt-4">Testimonio Andrés</h3>
                                            <div>
                                            <iframe width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/Xxif1Dc_ifQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}




                            </div>
                        </div>
                    </div>
                </div>

    }




                {/* <ModalVideo style={{zIndex: 99999999, borderRadius: "30px"}} channel='custom' isOpen={this.state.VideoPitch}
                url='https://player.dojofullstack.com/?url=https://media.dojofullstack.com/bootcamp/ads/intro-vls-beta-v-3-1.mp4&poster=https://media.dojofullstack.com/bootcamp/ads/vlcsnap-2024-10-30-16h36m12s314.png' 
                onClose={() => this.setState({VideoPitch: false})} /> */}



{true &&


<div >

      <div className="floating-button-container btn-efect-live" style={{borderRadius: 0, backgroundColor: "white"}}>

        <div className="floating-button" style={{padding: "1px" } }>

<button onClick={() => {this.setState({isOpenSuscribe: true}); this.sendEventAddCart() } }
    className="btn-efect-2" style={{marginLeft: !window.type_device ? "13px": "", border: "1px solid white", backgroundColor: "#4b0082", padding: "10px 25px", fontSize: '21px', textTransform: "none", fontFamily: "Poppins, sans-serif"}}
    >
    <IoIosDocument className="mr-1" />
    Descargar Brochure
</button>

            <div>
               {/* <h5 className="my-0" style={{fontSize: "19px", color: "white", textAlign: "center", color: "white", fontStyle: "italic"}} >
                Por {this.state.price_completo}  al mes
                 </h5> */}

                {/* <div className="mt-0" style={{fontSize: "19px"}}>
                ¡Quiero Convertirme en
                    {window.type_device ? <> <br/> </>: <></>} Full Stack Developer!
                </div> */}
            
            </div>

        </div>

        </div>
</div>

}


{window.location.pathname.includes("beca") &&
  <div onClick={() => this.setState({isOpenModalInfo: true})} className="floating-button-container btn-efect-live" style={{borderRadius: 0}}>

  <div className="floating-button" style={{padding: "3px"}}>

      <div>

          <div className="mt-1" style={{fontSize: "21px"}}>
          ¡Aplica para una Beca Aquí!
          </div>
      
      </div>

  </div>

  </div>

}


{ (this.state.showDeadLine && this.state.isFlashEspecial && this.state.timeCounter !== "") &&
    <a href={`/inscripcion/${this.state.bootcampSlugDescuento}/${this.state.countryCode}`}>
    <div id="counter-deadline" className="pt-1 pb-2">
        <h5 className="w-100 mt-2 mb-1 text-white text-center px-1" style={{fontSize: "17px"}} > 
            {this.state.porcentajeDescuento}
        </h5>
        <h5 className="my-0" style={{fontSize: "18px", color: "mediumspringgreen", textAlign: "center"}} >
            Solo 3 Mensualidades de {this.state.price_completo}   
        </h5>
        <FlipCountdown
            size={'small'}
            yearTitle='Year'
            dayTitle='Días'
            hourTitle='Horas'
            minuteTitle='Min'
            secondTitle='Segs'
            hideYear
            hideMonth
            // hideDay
            endAt={this.state.timeCounter?.toISOString()} // Date/Time
            onTimeUp={() => location.reload()}
            />
    </div>
    </a>
    }




{this.state.showWeb && 
                 <FooterTwo langUS={this.state.langUS} />
    }
                 
            </Fragment>
        )
    }




    runtDiscountHot(country){
        console.log("module runDiscountFlash!");

        const getCookieValue = (name) => {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }

        const discount60mValue = getCookieValue(`discount-temporal-${this.state.bootcampSlugDescuento}`);
        const discount24hValue = getCookieValue(`discount-reboot-${this.state.bootcampSlugDescuento}`);

        if (discount60mValue){
            console.log("DESCUENTO ACTIVO!");
            const discount60mDate = new Date(parseInt(discount60mValue, 10));
            // console.log(discount60mDate);
            this.setState({timeCounter: discount60mDate})
            return;
        }


        if ( !discount60mValue && !discount24hValue){
            console.log("CREANDO DESCUENTO!");

            const fechaActual = new Date();
            const fechaActual2 = new Date();
    
            const minutes = 60*24;
            const houresReboot = 48;
    
            fechaActual.setTime(fechaActual.getTime() +  (minutes * 60 * 1000) );
            fechaActual2.setTime(fechaActual2.getTime() +  (houresReboot * 60 * 60 * 1000) );
    
            document.cookie = `discount-temporal-${this.state.bootcampSlugDescuento}=${fechaActual.getTime()};expires=${fechaActual.toUTCString() }; path=/`;
            document.cookie = `discount-reboot-${this.state.bootcampSlugDescuento}=${fechaActual2.getTime()};expires=${fechaActual2.toUTCString() }; path=/`;
            this.setState({timeCounter: fechaActual})
        } else if (!discount60mValue && discount24hValue){
            console.log("FINALIZO EL DESCUENTO!");
            this.setState({showDeadLine: false});
        }
       
    }

    

    componentDidMount() {
        this.fetchPricingData();
    }

    async fetchPricingData() {


        this.setState({showWeb: true});

        const pathdata = window.location.pathname;
        // console.log('pathdata', pathdata);
        const url = new URL(window.location.href);
        const session_id = url.searchParams.get('session_id');
        const invoice_id = url.searchParams.get('invoice_id');
        const checkout_mount = url.searchParams.get('mount');

    

        if (pathdata.includes("payment-success")){
            // console.log("enviar evento pixel pago completado");

            if (session_id || invoice_id){
                console.log("Log ADS: session_id", session_id || invoice_id, parseInt(checkout_mount) || 497);
                window.fbq('track', 'Purchase', {
                    value: parseInt(checkout_mount) || 497,   // Valor total de la compra
                    currency: 'USD',               // Moneda utilizada (ISO 4217)
                    content_type: 'product',       // Tipo de contenido
                    content_ids: ['bootcamp'],  // ID del producto o identificador
                    content_name: 'bootcamp',
                    eventID: session_id || invoice_id,
                });
            }
      

            if (invoice_id){
                // Mostrar el valor en la consola
              window.open("https://plataforma-dojopy-media.s3.us-east-1.amazonaws.com/INVOICES/" + invoice_id + ".pdf", '_blank').focus();
            }

            // Obtener la URL actual
            // Leer el parámetro 'session_id'
    
     
        }


        if (pathdata.includes("unirme")){
            const iframe = document.getElementById('iframe-video');
            const estilo = window.getComputedStyle(iframe);
            const anchoExacto = parseInt(estilo.width, 10)
        
            const windowWidth = window.type_device ? window.innerWidth : anchoExacto;
            // Calcular el alto basado en la proporción 16:9
            const aspectRatio = 16 / 9;
            const height = windowWidth / aspectRatio;
            iframe.style.height = height  + 'px';

            this.sleep(30000).then(r => {
                window.fbq('track', 'ViewContent');
                this.eventPing("PLAY-VIDEO-30");
              })
          
            this.sleep(1000).then(r => {
                this.setState({showWeb: true});
                document.getElementById('whatsapp').style.display='block';
              })

        } else {
            this.setState({showWeb: true});


            // if (!window.location.pathname.includes("beca") ){
            //     document.getElementById('whatsapp').style.display='block';
            // } 

        }





        const isParameterPresent = (param) => {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.has(param);
        }

        // const isDiscountActive = isParameterPresent("discount");
        // if (isDiscountActive){
        //     console.log("Descuento activo flex-now!");
        //     this.setState({showDeadLine: true, bootcampSlug: "fullstack-especial", bootcampSlugDescuento: "fullstack-especial"})
        // }


        
        let fechaActual = new Date();

        try {
            const response = await axios.get("https://hiuaik2gocmwdeh2ub6lqz3hxq0ycvbe.lambda-url.us-east-1.on.aws/");
            let dataPrice = response.data;
            this.setState({pricingAPI: dataPrice})

            this.DynamicCountry();

      
            try {
                const pathdata = window.location.pathname;
                if (pathdata.includes("/us")){
                    this.setState({countryCode: "US"});
                    this.DynamicCountry("US");
                } else {

                    const responseIP = await axios.get("https://ipapi.co/json/");
                    let dataPriceIP = responseIP.data;
                    if (!dataPriceIP.country_code) throw "api error";


                    // if (dataPriceIP.country_code.toLocaleUpperCase() === "US" || dataPriceIP.country_code.toLocaleUpperCase() === "CA"){
                    //     window.location.href = "/en";
                    //     return;
                    // }

    
                    this.setState({countryCode: dataPriceIP.country_code});
                    this.DynamicCountry(dataPriceIP.country_code);

                }

            } catch {
                axios.get('https://bdzpehnjfmdq56bcrp6gb4mcru0nxqci.lambda-url.us-east-1.on.aws/').then((response) => {
                    let data = response.data;

                    // if (data.country_code.toLocaleUpperCase() === "US" || data.country_code.toLocaleUpperCase() === "CA"){
                    //     window.location.href = "/en";
                    //     return;
                    // }


                    this.setState({countryCode: data.country_code});
                    this.DynamicCountry(data.country_code);

                    // if (pathdata.includes('beca50')){
                    //     window.location.href = `/inscripcion/beca50/${data.country_code}`;
                    // } else if (pathdata.includes('unirme')){
                    //     window.location.href = `/inscripcion/fullstack/${dataPriceIP.country_code}`;
                    // }

                    }).catch((error) => {
                        console.log("error api");
                    });
            }



        } catch (error) {
            console.log(error);
          }





        this.sleep(20000).then(r => {
            toast.success("¡Geiler y Jhon ya se inscribieron, ahora es tu turno de unirte!");
        })


//         this.sleep(1000).then(r => {
//             toast.success(<>
//                <div className="row" >
//                <div className="d-flex mb-1 col-12" style={{borderRadius: "20px", padding: "5px", alignItems: "center"}}>
//     <img className="ml-1" style={{borderRadius: "20px", marginRight: "5px"}} height="65px" src="/assets/images/dojopy/1-face.webp" />
//     <div className="ml-2 mt-2">
//       <h5 style={{fontSize: "17px", color: "#1f1f25", fontStyle: "italic"}} className="my-0">Elon Musk
//         <span style={{marginLeft: "5px", fontSize: "13px", color: "mediumseagreen"}}>⭐⭐⭐</span>
//       </h5>
//       <p style={{fontSize: "11px", fontStyle: "italic"}}>estuvo genial recmomiendo!!! Acaba de inscribirse!.</p>
//     </div>
//   </div>
//                </div>
               
//             </>);
//         })



        this.sleep(20000).then(r => {
            this.setState({isOpenSuscribe: true});
        })


        this.sleep(3000).then(r => {
            this.setState({showWebNow: true, showBtnWasi: true})
            document.getElementById('whatsapp').style.display='block';
        })


        const email = localStorage.getItem('email');
        const name = localStorage.getItem('name');
        const phone = localStorage.getItem('phone');

        if (email){
            this.setState({Email: email});
        }
        if (name){
            this.setState({Name: name});
        }
        if (phone){
            this.setState({Phone: phone});
        }


    }

}


export default BootcampFullStackLiveEmpleo;
